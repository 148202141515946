import { Observable } from 'rxjs';
import { BaseDependencyProperty, DependencyProperty, DependencyResolvedData, DynamicDependencyProperty } from '../../models/base.models';

export abstract class ForeignKeyEntityResolverProvider {
    baseUrl: string;
    abstract checkDependenciesAndResolve<T>(entities: T, dependencies: DependencyProperty[]| DynamicDependencyProperty[]): Observable<T>;
    abstract checkDependenciesAndResolveForSingleEntity<T>(entity: T, dependencies: DependencyProperty[]| DynamicDependencyProperty[]): Observable<T>;
    abstract getDependencyData<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>>;
}

