import { ElementRef } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export abstract class LoaderServiceProvider {

    public loaderSubject = new Subject();
    public loaderSubjectCounter = new BehaviorSubject(0);
    public loadCounter: number = 0;
    public hasOverlayCreated: boolean = false;

    abstract showOverlay(element: ElementRef): void;
    abstract updateOverlayPosition(element: ElementRef): void;
    abstract closeOverlay(): void;

    constructor() { }

    incrementLoaderCount() {
        this.loadCounter++;
        this.loaderSubjectCounter.next(this.loadCounter);
    }

    decrementLoaderCount() {
        this.loadCounter--;
        this.loaderSubjectCounter.next(this.loadCounter);
    }

    showLoader() {
        this.loaderSubject.next(true);
    }

    hideLoader() {
        this.loaderSubject.next(false);
    }
}