import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter-pipe';
import { ReadablePipe } from './readable.pipe';
import { CurrencyConverterPipe } from './currencyConverter.pipe';
import { DateStringFormatPipe } from './date-format.pipe';



@NgModule({
  declarations: [FilterPipe,
    ReadablePipe,
    CurrencyConverterPipe,
    DateStringFormatPipe],
  imports: [
    CommonModule
  ],
  exports: [FilterPipe,
    ReadablePipe,
    CurrencyConverterPipe,
    DateStringFormatPipe]
})
export class PipesModule { }
