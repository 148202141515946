import * as moment_ from 'moment-timezone';
import { OrganizationDate } from "./org-date-helper";

export class DateTimeHelper {
    static moment = moment_;
    static ISO_DATE_TIME: RegExp;

    static convertToTimeString(dateTimeInMilliSeconds: string | number | Date | any) {
        var d = new Date(dateTimeInMilliSeconds);
        var h = d.getHours();
        var m: any = d.getMinutes();
        var s: any = d.getSeconds();
        var tms = dateTimeInMilliSeconds / 1000;
        var dec = (tms - Math.floor(tms)).toFixed(2).slice(1);
        var mer = 'AM';
        if (h > 11) {
            h %= 12;
            mer = 'PM';
        }
        if (h === 0) h = 12;
        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return h + ':' + m + ':' + s + dec + ' ' + mer;
    }

    static convertToDateString(dateTimeInMilliSeconds: string | number | Date) {
        return new Date(dateTimeInMilliSeconds).toDateString();
    }

    static getBeginningOfDayInUTC(dateTimeWithOffset: string) {
        var startOfDay = dateTimeWithOffset.slice(0, 11) + "00:00:01";
        return this.moment(startOfDay).toISOString();
    }


    static getEndOfDayInUTC(dateTimeWithOffset: string) {
        var endOfDay = dateTimeWithOffset.slice(0, 11) + "23:59:59";
        return this.moment(endOfDay).toISOString();
    }


    static getTimeInUTC(dateTimeWithOffset: string, time: string) {
        var endOfDay = dateTimeWithOffset.slice(0, 11) + time + dateTimeWithOffset.slice(19, 25);
        return this.moment(endOfDay).toISOString().slice(11, 19);
    }

    static convertUTCTimeToOrgTime(dateTimeWithOffset: string, utcTime: string, orgTimezone: string) {
        var isoDateTime = dateTimeWithOffset.slice(0, 11) + utcTime + ".000Z";
        var date: any = this.moment(isoDateTime);
        return date.tz(orgTimezone).format("HH:MM:SS");
    }

    static convertMinuteIntoHours(minutes: number) {
        const min = minutes % 60;
        const hours = Math.floor(minutes / 60);
        return hours === 0 ? + Math.floor(min) + " min" : hours + " hr " + Math.floor(min) + " min";
    }

    static getMinutesBetweenDates(startDate: string, endDate: string, outputFormat?: string) {
        outputFormat ? outputFormat = outputFormat : outputFormat = "HH:mm";
        return this.moment.utc(this.moment(endDate, "YYYY-MM-DDHH:mm:ss").diff(this.moment(startDate, "YYYY-MM-DDHH:mm:ss"))).format(outputFormat);
    }

    static convertDateTimeToTime(date: any, orgTimeZone: string) {
        const timeNow = this.moment.tz(date, orgTimeZone);
        let hours = timeNow.hours().toString().length === 1 ? "0" + timeNow.hours().toString() : timeNow.hours();
        let minutes = timeNow.minutes().toString().length === 1 ? "0" + timeNow.minutes().toString() : timeNow.minutes();
        return hours + ":" + minutes;
    }

    static isUtcDateTime(value: string) {
        this.ISO_DATE_TIME = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;
        return this.ISO_DATE_TIME.test(value);
    }


    static recursivelyConvertUTCDateTimeToOrgTime(data: any, obj: { [x: string]: string | null; } | null, property: string | null) {
        if (data != null && data != undefined) {
            if (typeof data === 'object') {
                if (data.constructor === Array) {
                    for (var i = 0; i < data.length; i++) {
                        DateTimeHelper.recursivelyConvertUTCDateTimeToOrgTime(data[i], null, null);
                    }
                } else {
                    for (var propertyName in data) {
                        DateTimeHelper.recursivelyConvertUTCDateTimeToOrgTime(data[propertyName], data, propertyName);
                    }
                }
            }
            else if (obj && property && DateTimeHelper.isUtcDateTime(data)) {
                obj[property] = OrganizationDate.convertIsoUtcDateTimeToOrgDateTime(data);
            }
        }
    }
}