import { BasePeriod } from '../models/base.models';
import { IdentityGenerator } from '../utils/identity-generator';

export class YearModeFormats {
    static YEAR_MODE_FORMATS = {
        parse: {
            dateInput: 'YYYY',
        },
        display: {
            dateInput: 'YYYY',
        },
    };
}

export class DateTimeFormatConstants {
    static DATE_FORMAT = 'YYYY-MM-DD';
    static DDMMYYYY = 'DD/MM/YYYY';
    static TIME_FORMAT = 'HH:mm:ss';
    static DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
}

export class RoleConstant {
    static ADMIN = IdentityGenerator.getLongId(1);
}
export class OperationType {
    static INSERT = 'insert';
    static DELETE = 'delete';
    static UPDATE = 'update';
    static ADD = 'add';
}


export class UrlTypes {
    static BACK_END = IdentityGenerator.getLongId(1);
    static FRONT_END = IdentityGenerator.getLongId(2);
    static WEB_SOCKET = IdentityGenerator.getLongId(3);
    static UPLOADS = IdentityGenerator.getLongId(4);
    static SENTRY = IdentityGenerator.getLongId(5);
    static TILES = IdentityGenerator.getLongId(6);
}
export class ActionName {
    static DELETE = 'Delete';
}
export class DateRangePeriods {
    static DAY = 4;
    static HOUR = 5;
    static MINUTE = 6;
    static HOUR_PERIOD = { id: 5, name: 'hours', format: 'HH:mm' };
    static WEEK_PERIOD = { id: 3, name: 'weeks', format: 'DD MMM' };
    static list: BasePeriod[] = [
        { id: 1, name: 'years', format: 'YYYY' }, { id: 2, name: 'months', format: 'MMM YY' },
        { id: 3, name: 'weeks', format: 'DD MMM' }, { id: 4, name: 'days', format: 'DD/MM/YY' },
        { id: 5, name: 'hours', format: 'HH:mm' }, { id: 6, name: 'minutes', format: 'HH:mm' }];
    static getList() {
        return DateRangePeriods.list;
    }
}
export class PasswordRecoveryConstants {
    static EMAIL = 'Email';
    static PHONE = 'Phone';
    static RECOVERY_PASSWORD = 'recoverPassword';
    static UPDATE_PASSWORD = 'updatePassword';
    static LOGIN_FORM = 'login';
}
export class Modules {
    static GLOBAL_CONFIGURATION = IdentityGenerator.getLongId(1);
    static SD = IdentityGenerator.getLongId(10);
    static ASSET = IdentityGenerator.getLongId(3);
    static OPERATIONAL_PLAN = IdentityGenerator.getLongId(4);
    static PRODUCTION = IdentityGenerator.getLongId(6);
    static MOBILE = IdentityGenerator.getLongId(11);
    static SPECIAL_PERMISSION = IdentityGenerator.getLongId(12);
    static HF = IdentityGenerator.getLongId(2);
    static DATA_FACTORY = IdentityGenerator.getLongId(15);
    static REPORT_MODULE = IdentityGenerator.getLongId(13);
}
export enum WebStorageEnum {
    PREVIOUS_SITE_ID = 'previousSiteId',
    SITE_ID = 'siteId',
    SLN_CODE = 'slnCode',
    CURRENT_PAGE = 'currentPage',
    DEFAULT_PAGE = 'defaultPage',
    PREVIOUS_MODULE_ID = 'previousModuleId',
    MODULE_ID = 'ModuleId',
    USER_PROFILE = 'userProfile',
    USER_INFO = 'userInfo',
    AUTHORIZATION_KEY = 'Authorization',
    DB_KEY = 'dbKey',
    LOGGED_IN = 'logged_in',
    ORG_SETTINGS = 'orgSettings',
    SITE_DETAILS = 'siteDetails',
    CURRENT_ORG_TIME = 'currentOrgTime',
    USER_PERMISSIONS = 'userPermissions',
    BUILD_VERSION = 'buildVersion',
    MODULE_DEPLOYMENT = 'moduleDeployment',
    ENTITY_SCHEMAS = 'entitySchemas',
    APPLICATION_CONFIG = 'applicationConfig',
    DB_KEY_PREFIX = "rt-db",
    SITE = 'SITE',
    DATA_ENTITY = 'dataEntities',
    CLIENT_LOGO = 'client_logo',
    CURRENCY_CONVERTER = 'currency_converter',
    COGNITO_PROFILE = 'cognitoProfile',
    AUTH_STATE = 'authState',
    USERPOOL = "USERPOOL",
    RUNNING_ENV = "RUNNING_ENV",
    INFRA_REGISTERED = "INFRA_REGISTERED",
    IS_AGENT_INSTALLED = "IS_AGENT_INSTALLED",
    REGION = "Region",
    DC_REGION = "DC_REGION"
}

export class HttpHeaderConstants {
    static SITE_ID = 'siteId';
    static AUTHORIZATION_KEY = 'Authorization';
    static IP_ADDRESS = 'ipAddress';
    static USER_AGENT = 'User-Agent';
    static GEO_LOCATION = 'geoLocation';
}

export class FormStatus {
    static INVALID = 'INVALID';
    static DEFAULT_LABEL = 'save';
}

export class Config {
    static KEY = 'config';
}
export enum DynamicFeatGroupOperationType {
    OPERATION_TYPE_ADD = 'add',
    OPERATION_TYPE_UPDATE = 'edit',
    OPERATION_TYPE_DELETE = 'delete',
    OPERATION_TYPE_LIST = 'view',
    CUSTOM_PAGE_PERMISSION_CONSTANT = 'cc8b69e5-b603-43ab-b0dd-ebb1357fd16a'
}
export enum URLConstants {
    INSTANCE_DETAILS = 'instanceDetails/'
}
export enum RouterEnum {
    CURRENT_PAGE = "currentPage",
    PREVIOUS_SITE_ID = "previousSiteId",
    DEFAULT_PAGE = "defaultPage"
}
export class CrudType {
    static INSERT = 1;
    static UPDATE = 2;
    static DELETE = 3;
    static REFRESH = 4;
}
export class UploadImage {
    static IMAGE_UPLOAD_PATTERN = (/\.(jpg|jpeg|png|gif|tif|tiff)$/);
    static IMAGE_UPLOAD_LENGTH = 40;
}

export class MemoryType {
    static CPU = 1;
    static milli_CPU = 2;
    static GB = 1;
    static MB = 2;
}