import { Injector } from '@angular/core';
import { CrudType } from 'libs/dc-core/constants/constant';
import { GlobalBaseEntity, DependencyProperty, AdvancedFilter } from 'libs/dc-core/models/base.models';
import { ForeignKeyEntityResolverProvider } from 'libs/dc-core/providers/abstract/foreign-key-entity-Provider';
import { HttpClientProvider } from 'libs/dc-core/providers/abstract/http-client-provider';
import { LocalStorageProvider } from 'libs/dc-core/providers/abstract/indexdb-manager-service-provider';
import { LoaderServiceProvider } from 'libs/dc-core/providers/abstract/loader-service-provider';
import { PageRefreshService } from 'libs/dc-core/providers/page-refresh/page-refresh.service';
import { Observable, Observer, Subject } from 'rxjs';
import { BaseApiHelper } from './base-api-helper';


export interface IDevumBaseReaderApi {
    get<T>(url: string, params?: { [param: string]: string | string[] | any; }): Observable<T>;
    getById<T extends GlobalBaseEntity>(id: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    getList<T>(dependencies?: Array<DependencyProperty>, includeRemovedItems?: boolean): Observable<T>;
    getPreciseList<T>(includeRemovedItems?: boolean): Observable<T>;
    getWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    getListWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByFilter<T>(advancedFilter: AdvancedFilter, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByFilterWithUrl<T>(advancedFilter: AdvancedFilter, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByCustomFilter<T>(data: any, dependencies?: Array<DependencyProperty>): Observable<T>;
    getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T>;
    searchByLabel<T>(data: { label: string; }, dependencies?: Array<DependencyProperty>): Observable<T[]>;
}

export class DenBaseReaderApi implements IDevumBaseReaderApi {

    isCacheable: boolean;
    protected baseUrl: string;
    protected baseUrlForCache: string;
    http: HttpClientProvider;
    public loaderService: LoaderServiceProvider;
    public indexedDbService: LocalStorageProvider;
    public dependencyService: ForeignKeyEntityResolverProvider;
    public pageRefresh = this.injector.get(PageRefreshService);
    public deleteSelectionPublisher = new Subject();
    entity = new Subject();
    public name: string;

    constructor(public injector: Injector, url: string, entityName: string, isCacheable: boolean) {
        this.baseUrl = url + entityName;
        this.name = entityName;
        this.baseUrlForCache = url;
        this.isCacheable = isCacheable;
        this.initProviders(url);
    }

    private initProviders(Url: string) {
        this.http = this.injector.get(HttpClientProvider);
        this.loaderService = this.injector.get(LoaderServiceProvider);
        this.indexedDbService = this.injector.get(LocalStorageProvider);
        this.dependencyService = this.injector.get(ForeignKeyEntityResolverProvider);
        this.dependencyService.baseUrl = Url;
    }

    getEntity() {
        return this.entity.asObservable();
    }

    getSelectedAndDeleteEntityAsObservable() {
        return this.deleteSelectionPublisher.asObservable();
    }

    getList<T>(dependencies?: Array<DependencyProperty>, includeRemovedItems?: boolean): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            if (this.isCacheable) {
                this.loaderService.incrementLoaderCount();
                return this.indexedDbService.get(this.name).subscribe(
                    (data: any) => {
                        this.loaderService.decrementLoaderCount();
                        if (data) {
                            this.getResolvedData(JSON.parse(JSON.stringify(data)), observer, dependencies, includeRemovedItems);
                        } else {
                            this.loaderService.incrementLoaderCount();
                            this.get<T>('', { includeRemovedItems: true })
                                .subscribe((data: T) => {
                                    this.indexedDbService.set(this.name, JSON.parse(JSON.stringify(data))).subscribe();
                                    this.getResolvedData(data, observer, dependencies, includeRemovedItems);
                                    this.loaderService.decrementLoaderCount();
                                });
                        }
                    });
            } else {
                this.loaderService.incrementLoaderCount();
                return this.get<T>('', { includeRemovedItems: true })
                    .subscribe(
                        (data: T) => {
                            this.getResolvedData(data, observer, dependencies, includeRemovedItems);
                            this.loaderService.decrementLoaderCount();
                        },
                        (error) => {
                            observer.error(error);
                            this.loaderService.decrementLoaderCount();
                        });
            }
        });
    }

    getPreciseList<T>(includeRemovedItems: boolean = false): Observable<T> {
        //TODO: Vijay to revisit
        //@ts-ignore
        return new Observable((observer: Observer<T>) => {
            if (this.isCacheable) {
                this.loaderService.incrementLoaderCount();
                return this.indexedDbService.get(this.name + '/precis').subscribe(
                    (data: any) => {
                        this.loaderService.decrementLoaderCount();
                        if (data) {
                            if (!includeRemovedItems) {
                                data = BaseApiHelper.filterIsRemovedItems(JSON.parse(JSON.stringify(data)), false);
                            }
                            observer.next(JSON.parse(JSON.stringify(data)));
                        } else {
                            this.loaderService.incrementLoaderCount();
                            this.get<T>('/precis', { includeRemovedItems: includeRemovedItems })
                                .subscribe(
                                    (data: T) => {
                                        this.loaderService.decrementLoaderCount();
                                        this.indexedDbService.set(this.name + '/precis', JSON.parse(JSON.stringify(data))).subscribe();
                                        if (!includeRemovedItems) {
                                            data = BaseApiHelper.filterIsRemovedItems(data, false);
                                        }
                                        observer.next(data);
                                    }, (error: ErrorEvent) => {
                                        observer.error(error);
                                        this.loaderService.decrementLoaderCount();
                                    });
                        }
                    });
            }else{
                this.loaderService.incrementLoaderCount();
                this.get<T>('/precis', { includeRemovedItems: includeRemovedItems })
                    .subscribe(
                        (data: T) => {
                            this.loaderService.decrementLoaderCount();
                            if (!includeRemovedItems) {
                                data = BaseApiHelper.filterIsRemovedItems(data, false);
                            }
                            observer.next(data);
                        }, (error: ErrorEvent) => {
                            observer.error(error);
                            this.loaderService.decrementLoaderCount();
                        });
            }
        });
    }

    getWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            this.loaderService.incrementLoaderCount();
            return this.get<T>(url)
                .subscribe(
                    (data: T) => {
                        this.loaderService.decrementLoaderCount();
                        this.loaderService.incrementLoaderCount();
                        this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
                            (data: T) => {
                                observer.next(data);
                                this.loaderService.decrementLoaderCount();
                            }
                        );
                    }, (error: ErrorEvent) => {
                        observer.error(error);
                        this.loaderService.decrementLoaderCount();
                    });
        });
    }

    getByFilter<T>(advancedFilter: AdvancedFilter, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByFilterWithUrl(advancedFilter, '/filter', dependencies);
    }

    getByFilterWithUrl<T>(advancedFilter: AdvancedFilter, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByAnyFilterType(advancedFilter, url, dependencies);
    }

    getByCustomFilter<T>(data: any, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByAnyFilterType(data, '/filter', dependencies);
    }

    getByCustomFilterWithUrl<T>(data: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return this.getByAnyFilterType(data, url, dependencies);
    }

    getByAnyFilterType<T>(payload: any, url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            this.loaderService.incrementLoaderCount();
            return this.post<T>(url, payload).subscribe(
                (data: T) => {
                    this.loaderService.decrementLoaderCount();
                    this.loaderService.incrementLoaderCount();
                    this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
                        (dependencyData: T) => {
                            observer.next(dependencyData);
                            this.loaderService.decrementLoaderCount();
                            // if (isMultipleDelete === true) {
                            //     this.publishOnDeleteMultipleEntities(isMultipleDelete, payload.ids);
                            // }
                        });
                    // observer.next(data);
                }, (error: ErrorEvent) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                });
        });
    }



    get<T>(url: string, params?: { [param: string]: string | string[] | any; }): Observable<T> {
        const constructedUrl = this.baseUrl + this.constructUrl(url);
        return this.http.get<T>(constructedUrl, params);
    }

    getListWithUrl<T>(url: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            this.loaderService.incrementLoaderCount();
            return this.get<T>(url)
                .subscribe(
                    (data: T) => {
                        this.loaderService.decrementLoaderCount();
                        this.loaderService.incrementLoaderCount();
                        this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
                            (data: T) => {
                                observer.next(data);
                                this.loaderService.decrementLoaderCount();
                            }
                        );
                    }, (error: ErrorEvent) => {
                        observer.error(error);
                        this.loaderService.decrementLoaderCount();
                    });
        });
    }

    getById<T extends GlobalBaseEntity>(id: string, dependencies?: Array<DependencyProperty>): Observable<T> {
        //TODO: Vijay to revisit
        //@ts-ignore
        return new Observable((observer: Observer<T>) => {
            if (typeof id === 'string') {
                id = id.replace('/', '');
                // name = parseInt(name, 10);
            }
            if (this.isCacheable) {
                this.loaderService.incrementLoaderCount();
                return this.indexedDbService.get(this.name).subscribe(
                    (response: any) => {
                        this.loaderService.decrementLoaderCount();
                        if (response) {
                            let data = JSON.parse(JSON.stringify(response));
                            const result = data.find((item: { id: string; }) => item.id === id);
                            if (result) {
                                this.loaderService.incrementLoaderCount();
                                this.dependencyService.checkDependenciesAndResolve(result, dependencies).subscribe(
                                    (dependencyData: T) => {
                                        observer.next(dependencyData);
                                        this.loaderService.decrementLoaderCount();
                                    }
                                );
                            } else {
                                this.getByIdFromDbForNonTransactionalEntity<T>(id, observer, dependencies);
                            }
                        } else {
                            this.getByIdFromDbForNonTransactionalEntity<T>(id, observer, dependencies);
                        }
                    });
            } else {
                this.getByIdFromDbForTransactionalEntity<T>(id, observer, dependencies);
            }
        });
    }

    searchByLabel<T>(data: { label: string; }, dependencies?: Array<DependencyProperty>): Observable<T[]> {
        return this.getByAnyFilterType(data, 'searchByLabel', dependencies);
    }

    removeEntityFromCache() {
        return new Promise((resolve, reject) => {
            let getListUrl = this.name;
            let getPreciseListUrl = this.name + '/precis';
            return Promise.all([this.checkAndRemoveFromCache(getListUrl), this.checkAndRemoveFromCache(getPreciseListUrl)]).then(resolve, reject);
        });
    }

    constructUrl(url: string | any) {
        if (!url) {
            return '';
        }
        if (!url.startsWith('/')) {
            return '/'.concat(url);
        } else {
            return url;
        }
    }

    private checkAndRemoveFromCache(name: string) {
        return new Promise((resolve, reject) => {
            this.indexedDbService.get(name).subscribe((data) => {
                if (data) {
                    this.indexedDbService.remove(name).subscribe();
                }
                resolve('success');
            }, (error: ErrorEvent) => reject(error.message));
        });
    }

    private getResolvedData<T>(data: T, observer: Observer<T>, dependencies?: Array<DependencyProperty>, includeRemovedItems?: boolean) {
        if (!includeRemovedItems) {
            data = BaseApiHelper.filterIsRemovedItems<T>(data, false);
        } else {
            observer.next(data);
        }
        this.loaderService.incrementLoaderCount();
        this.dependencyService.checkDependenciesAndResolve(data, dependencies).subscribe(
            (data: T) => {
                observer.next(data);
                this.loaderService.decrementLoaderCount();
            }
        );
    }

    private getByIdFromDbForTransactionalEntity<T>(id: string, observer: Observer<T>, dependencies?: Array<DependencyProperty>) {
        this.loaderService.incrementLoaderCount();
        this.get<T[]>('/' + id, { includeRemovedItems: true })
            .subscribe(
                (data: T[]) => {
                    this.loaderService.decrementLoaderCount();
                    if (data) {
                        this.loaderService.incrementLoaderCount();
                        this.dependencyService.checkDependenciesAndResolveForSingleEntity(data, dependencies).subscribe(
                            (response: any) => {
                                observer.next(response);
                                this.loaderService.decrementLoaderCount();
                            }
                        );
                    }
                }, (error: ErrorEvent) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                });
    }

    private getByIdFromDbForNonTransactionalEntity<T extends GlobalBaseEntity & {id: string}>(id: string, observer: Observer<T>, dependencies?: Array<DependencyProperty>) {
        this.loaderService.incrementLoaderCount();
        this.get<T[]>('', { includeRemovedItems: true })
            .subscribe(
                (data: T[]) => {
                    this.loaderService.decrementLoaderCount();
                    if (this.isCacheable) {
                        this.indexedDbService.set(this.name, JSON.parse(JSON.stringify(data))).subscribe();
                    }
                    const dataById = data.find(entity => entity.id === id);
                    if (dataById) {
                        this.loaderService.incrementLoaderCount();
                        this.dependencyService.checkDependenciesAndResolve(dataById, dependencies).subscribe(
                            (response: T) => {
                                observer.next(response);
                                this.loaderService.decrementLoaderCount();
                            }
                        );
                    }
                }, (error: ErrorEvent) => {
                    observer.error(error);
                    this.loaderService.decrementLoaderCount();
                });
    }

    private post<T>(url: string, entity: T | T[]): Observable<any> {
        const constructedUrl = this.baseUrl + this.constructUrl(url);

        return new Observable((observer) => {
            this.http.post<T>(constructedUrl, entity).subscribe(res => {
                // if (this.isCacheable) {
                //     this.removeEntityFromCache();
                // }
                observer.next(res);
            }, (error: ErrorEvent) => observer.error(error));
        });
    }

    public validate(data: any) {
        let message: boolean;
        if (data.results && data.results.length > 0) {
            message = true;
        } else {
            message = false;
        }
        return message;
    }

    // closeFlexContent() {
    //     document.body.classList.remove('flex-content');
    //     document.body.classList.remove('minified');
    //     document.body.classList.remove('flex-dock-left');
    // }

    notifyObservers(responseData?: any) {
        // TODO: How the pageRefresh can be handled. -- Prem
        this.pageRefresh.setActionInstance(null, CrudType.REFRESH);
        this.entity.next(responseData);
        console.warn('TO DO Implement notify Observers in individual module base-api.ts');
    }
}
