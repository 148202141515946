<div class="row rt-my-20">
  <div class="col-md-10">
    <h3 class="sub-title">Entity Summary</h3>
  </div>
  <div class="col-md-2 rt-pull-right">
    <button mat-button class="rt-dailog-close rt-pull-right" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div class="table-without-checkbox-wrapper rt-px-15 rt-mt-20">
    <table class="cdk-table rt-border">
      <thead>
        <tr class="cdk-header-row">
          <th *ngFor="let headerValue of headerValues" class="cdk-header-cell table-column-title">{{headerValue ?headerValue:"NA"}}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="cdk-row">
          <td *ngFor="let keyValue of keyValues" class="cdk-cell">{{keyValue ? keyValue:"NA"}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div mat-dialog-actions class="rt-mb-10 rt-justify-end">
  <div class="row">
    <div class="col-md-12  rt-buttons">
      <button mat-raised-button class="rt-mr-5" [mat-dialog-close]="false">Cancel</button>
      <button mat-raised-button color="accent" [mat-dialog-close]="removedEntityId">Activate</button>
    </div>
  </div>
</div>