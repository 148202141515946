import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rt-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {

  entity: { entityMessage: string, headerMessage?: string, confirmBtnLabel?: string; };

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.entity = this.data;
  }

  ngOnInit(): void {
    console.log(this.entity);
  }
  onClose() {
    this.dialogRef.close(true);
  }

  onCancelled(){
    this.dialogRef.close(false);
  }
}
