
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpRequestInterceptor } from './http.request.interceptor';
import { HttpResponseInterceptor } from './http.response.interceptor';
import { SessionStorageService } from '../../../src/app/components/storage/session-storage.service';
import { Auth } from 'aws-amplify';
import { ToastServiceProvider } from '../providers/abstract/toast-service-provider';
import { HttpStatusCode } from '../constants/http-constants';
import { Router } from '@angular/router';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    private baseUrl: string;
    private loadCounterSubscription: Subscription;
    // private sessionStorageService: SessionStorageService;
    constructor(@Inject(SessionStorageService) private sessionStorageService: SessionStorageService,
        private toastService: ToastServiceProvider, private router: Router) {
    }

    // setSessionStorageService(sessionservice: SessionStorageService) {
    //     this.sessionStorageService = sessionservice;
    // }

    // Ref: https://stackoverflow.com/questions/45735655/how-to-setup-baseurl-for-angular-httpclient
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const apiReq = HttpRequestInterceptor.setHttpHeaders(req, this.baseUrl, this.isUserIsLoggedIn(), this.sessionStorageService, this.router);

        return next
            .handle(apiReq).pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        HttpResponseInterceptor.intercept(event);
                    }
                }), catchError((error: HttpErrorResponse) => {
                    if (error.status === HttpStatusCode.UNAUTHORIZED) {
                        this.handleSessionExpiration();
                    }
                    return throwError(error);
                }));
    };



    private handleSessionExpiration() {
        Auth.signOut({ global: true })
            .then(data => {
                console.log(data);
                this.sessionStorageService.clearSession();
                this.toastService.showError('Session expired, Please login !');
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            })
            .catch(err => console.log(err));
    }

    ngOnDestroy(): void {
        this.loadCounterSubscription?.unsubscribe();
    }

    private isUserIsLoggedIn() {
        return this.sessionStorageService.getCognitoToken() === null ? false : true;
    }
}
