import { Injectable, OnInit, Inject } from '@angular/core';
import { Observable, PartialObserver } from 'rxjs';
import { LocalStorageProvider } from './abstract/indexdb-manager-service-provider';
import { SessionStorageService } from '../../../src/app/components/storage/session-storage.service';

@Injectable()
export class IndexDBManagerService extends LocalStorageProvider implements OnInit {
    private databaseName!: string;
    private tableName!: string;
    private database: any;
    private indexedDB: any = window.indexedDB;
    private version: any = 1;
    private dbOpenRequest: any;
    entityCacheMap: Map<string, any> = new Map();
    constructor(@Inject(SessionStorageService) private sessionStorageService: SessionStorageService) {
        super();
    }
    ngOnInit(): void {
    }

    init(databaseName: string, tableName: string): Observable<string> {
        if (!databaseName) {
            return new Observable(observer => {
                this.indexedDB.databases().then((indexDbs: any[]) => {
                    const indexdb = indexDbs.find(db => db.name === this.sessionStorageService.getDbKey());
                    if (indexdb) {
                        databaseName = indexdb.name;
                        this.initDatabase(databaseName, tableName, observer);
                    }
                }, (error: ErrorEvent) => {
                    observer.error(error);
                });
            });
        } else {
            return new Observable(observer => {
                this.initDatabase(databaseName, tableName, observer);
            });
        }
    }
    initDatabase(databaseName: string, tableName: string, observer: PartialObserver<any>) {
        this.databaseName = databaseName;
        this.tableName = tableName || 'EntityCache';
        const request = this.indexedDB.open(databaseName, this.version);
        request.onupgradeneeded = (event: any) => {
            this.tryCreateObjectStore(event, this.tableName);
        };
        request.onsuccess = (event: any) => {
            this.tryCreateObjectStore(event, this.tableName);
            this.database = event.target.result;
            this.dbOpenRequest = event.results;
            observer.next('Database initialized');
        };

        request.onerror = () => {
            observer.error('Database initialization failed');
        };
    }
    private tryCreateObjectStore(event: any, tableName: string) {
        const db = event.target.result;
        if (db.objectStoreNames.contains(tableName) === false) {
            db.createObjectStore(tableName);
        }
    }
    private getValuesFromStore(database: any, tableName: string, observer: PartialObserver<any>) {
        //https://googlechrome.github.io/samples/idb-getall/
        const transaction = database.transaction([tableName], 'readwrite');
        const objectStore = transaction.objectStore(tableName);
        if ('getAll' in objectStore) {
            // IDBObjectStore.getAll() will return the full set of items in our store.
            objectStore.getAll().onsuccess = (event: any) => {
                observer.next(event.target.result);
            };
        } else {
            // Fallback to the traditional cursor approach if getAll isn't supported.
            const data: any[] = [];
            objectStore.openCursor().onsuccess = (event: any) => {
                const cursor = event.target.result;
                if (cursor) {
                    data.push(cursor.value);
                    cursor.continue();
                } else {
                    observer.next(data);
                }
            };
        }
    }
    // getAll() {
    getAll<T>(): Observable<T[]> {
        return new Observable<T[]>(observer => {
            if (!this.database) {
                this.init(this.databaseName, this.tableName).subscribe(() => {
                    this.getValuesFromStore(this.database, this.tableName, observer);
                });
            } else {
                this.getValuesFromStore(this.database, this.tableName, observer);
            }
        });
    }

    private getFromInMemoryOrIndexedDb(database: any, tableName: string, key: string, observer: PartialObserver<any>) {
        //Check in memory for the entity by key, if not found check in indexeddb and update cache with the same
        const entityInMemory = this.entityCacheMap.get(key);
        if (entityInMemory) {
            observer.next(entityInMemory);
        } else {
            const transaction = database.transaction([tableName], 'readwrite');
            const objectStore = transaction.objectStore(tableName);
            const request = objectStore.get(key);
            request.onsuccess = (event: any) => {
                //Update cache with latest entity data
                this.entityCacheMap.set(key, event.target.result);
                observer.next(event.target.result);
            };
            request.onerror = (error: any) => {
                observer.error(error);
            };
        }
    }
    get<T>(key: string): Observable<T> {
        return new Observable<T>(observer => {
            if (!this.database) {
                this.init(this.databaseName, this.tableName).subscribe(() => {
                    this.getFromInMemoryOrIndexedDb(this.database, this.tableName, key, observer);
                });
            } else {
                this.getFromInMemoryOrIndexedDb(this.database, this.tableName, key, observer);
            }
        });
    }

    set<T>(key: string, value: T): Observable<string> {
        return new Observable(observer => {
            if (this.database) {
                const transaction = this.database.transaction([this.tableName], 'readwrite');
                const objectStore = transaction.objectStore(this.tableName);
                const request = objectStore.put(value, key);
                //Set latest entity data to entity cache map
                this.entityCacheMap.set(key, value);
                request.onsuccess = () => {
                    observer.next('Successfully set data to IndexedDb');
                };
                request.onerror = () => {
                    observer.error('Error while set data to IndexedDb');
                };
            } else {
                console.log('database not found to set ' + ' in indexdb');
            }
        });
    }

    remove(key: string): Observable<string> {
        return new Observable(observer => {
            const transaction = this.database.transaction([this.tableName], 'readwrite');
            const objectStore = transaction.objectStore(this.tableName);
            const request = objectStore.delete(key);
            //Delete entiy list from cache by key
            this.entityCacheMap.delete(key);
            request.onsuccess = () => {
                observer.next('Successfully deleted from IndexedDb');
            };
            request.onerror = () => {
                observer.error('Error while deleting data from IndexedDb');
            };
        });
    }

    clear(): Observable<string> {
        return new Observable(observer => {
            const transaction = this.database.transaction([this.tableName], 'readwrite');
            const objectStore = transaction.objectStore(this.tableName);
            const request = objectStore.clear();
            //Clear entire entity cache map       
            this.entityCacheMap.clear();
            request.onsuccess = () => {
                observer.next('Successfully cleared data from table :' + this.tableName + ' in IndexedDb');
            };
            request.onerror = () => {
                observer.error('Error while clear data from table :' + this.tableName + ' in IndexedDb');
            };
        });
    }

    delete(): Observable<string> {
        return new Observable(observer => {
            const request = window.indexedDB.deleteDatabase(this.databaseName);
            request.onsuccess = (_event) => {
                //Clear entire entity cache map       
                this.entityCacheMap.clear();
                observer.next('Successfully deleted database ' + this.databaseName);
            };
            request.onerror = (_error) => {
                observer.error('Error deleteing database ' + this.databaseName + ' from IndexedDb');
            };
        });
    }

    close(): void {
        if (this.dbOpenRequest) {
            this.dbOpenRequest.close();
        }
    }
}
