import { DenBaseEnumApi } from 'src/app/shared/base-apis/den-base-enum-api';
import { DenBaseReaderApi } from 'src/app/shared/base-apis/den-reader-base-api';
import { DynamicReaderApi } from 'src/app/shared/base-apis/dynamic-reader-api';
import { IdentityGenerator } from '../utils/identity-generator';

// export class BaseType {
//     id?: number | number[];
//     name?: string;
// }

export class BaseType {
    name?: string;
    label?: string;
}


// export interface IBaseEntity {
//     id: number;
//     isRemoved: boolean;
//     createDate: string | null;
//     createdBy: number | null;
//     siteId: number | null;
//     attributes: Object;
// }

// export class BaseEntity implements IBaseEntity {
//     public id: number = 0;
//     public isRemoved: boolean = false;
//     public createDate: string | null = null;
//     public createdBy: number | null = null;
//     public siteId: number | null = null;
//     public attributes: Object = {};
//     public labels: string[] = [];
// }

export class GlobalBaseEntity {
    public id: string = IdentityGenerator.guid();
    public isRemoved: boolean = false;
    // public attributes: Object = {};
    // public createdBy: string | null = null;
    // public createDate: string | null = null;
}

// export class GlobalBaseEntity {
//     public id: number = 0;
//     public isRemoved: boolean = false;
// }

//Run time its is currently called as AdditionalEntity
export interface DevumEntity {
}

export interface Cacheable {
}

export class UserTextInput {
    propName: string | undefined;
    value: string | undefined;
}
export class FilterDependency {
    propertyName: string | undefined;
    idPropertyName: string | undefined;
    value1: number | string | undefined;
    valueList: string | undefined;
}
export class ApplyFilterType {
    propertyName: string | undefined;
    operator: string | FilterOperator | undefined;
    value1: string | undefined;
    value2: string | undefined;
    valueList: number[] | undefined;
}
export class FilterPropertyNameValues {
    propertyName: string | undefined;
    value1: string | undefined;
    valueList: any[] | undefined;
}
export class RemoveFilterType {
    filterProperty: string | undefined;
    filterValue: string | number | undefined;
    isDate?: boolean;
}
export class FilterBody {
    propertyName: string | undefined;
    operator: FilterOperator | undefined;
    value1: any;
    value2: any;
    valueList: any;
}
export class Row {
    name: number | undefined;
}
export class ActionData {
    selectedAction: string | undefined;
    selectedRecord: {} | undefined;
}
export class ActionList {
    name: string | undefined;
    icon: string | undefined;
}
// export class previousFilter {
//     guid: string | undefined;
//     body: AdvancedFilter | undefined;
// }
export class TableResults {
    totalResults: number | undefined;
    results: Row[] | any[] | undefined;
}
export class GridListForExportPdf {
    propertyName: string | undefined;
    propertyValue: string | undefined;
    constructor() { }
}
export class BaseTable {
    name: string | undefined;
    isSelected: boolean | undefined;
    rowId: number | undefined;
    icon: string | undefined;
    label: string | undefined;
}
export class Api {
    getByFilter = function () { };
}
// export class DataEntity {
//     public id: number | undefined;
//     public name: string | undefined;
//     public description?: string;
//     public isRemoved: boolean | undefined;
//     public module?: Module;
//     public moduleId: number | undefined;
//     public basePath: string | undefined;
//     public isPublishable: boolean | undefined;
//     public isCacheable: boolean | undefined;
// }
export class DataEntity {
    public id: string | undefined;
    public name: string | undefined;
    public description?: string;
    public isRemoved: boolean | undefined;
    public basePath: string | undefined;
    public isPublishable: boolean | undefined;
    public isCacheable: boolean | undefined;
}
export enum EnumURLTypes {
    BACK_END = 900000000000001,
    FRONT_END = 900000000000002,
    WEB_SOCKET = 900000000000003,
    UPLOADS = 900000000000004,
    SENTRY = 900000000000005,
    TILES = 900000000000006
}
// export class EntitySchemaField extends BaseEntity {
//     public entitySchema?: EntitySchema;
//     public entitySchemaId: number | undefined;
//     public fieldName: string | undefined;
//     public description?: string;
//     public fieldTypeId: number | undefined;
//     public dataSource: string | undefined;
//     public isRequired: boolean | undefined;
//     public isUnique: boolean | undefined;
//     public regex?: string;
//     public sequenceNo?: number;
//     public labels: Array<string>;
//     public sortOrder?: number;
//     public modifiedDate: string | undefined;
//     public addToSummary: boolean | undefined;
//     public defaultValue?: string;
//     public referenceableProperty?: string;
//     public isDynamic: boolean | undefined;
//     public name: string | undefined;
//     public summary: string | undefined;
//     constructor() {
//         super();
//     }
// }
// export class ExtendedEntitySchemaField extends EntitySchemaField {
//     valueList: EntitySchemaDataResponse[] | undefined;
//     fields: EntitySchemaField[] | undefined;
// }

// export class Module extends GlobalBaseEntity {
//     public id: number;
//     public name: string;
//     public description?: string;
//     public isRemoved: boolean;
//     public isActive: boolean;
//     constructor() {
//         super();
//     }
// }

// export class EntitySchema extends MasterDataEntity {
//     public name: string | undefined;
//     public description?: string;
//     public module?: Module;
//     public moduleId: number | undefined;
//     public menuIcon?: string;
//     public menuItemName?: string;
//     public menuGroupName?: string;
//     public entityTypeId?: number;
//     public labels: Array<string> | undefined;
//     public sortOrder?: number;
//     public modifiedDate: string | undefined;
//     public add?: string;
//     public update?: string;
//     public cancel?: string;
//     public delete?: string;
//     public summary: string | undefined;
//     constructor() {
//         super();
//     }
// }

// export class EntitySchema extends BaseEntity {

//     public id: string;
//     public name: string;
//     public description?: string;
//     public isRemoved: boolean;
//     public attributes: Array<string>;
//     public labels: Array<string>;
//     public sortOrder?: number;
//     public modifiedDate: string;
//     public isSeedSchema: boolean;
//     public entityType: string;
//     public behaviourType: string;
//     public isPublishable: boolean;
//     public auditLogLevel: string;
//     public label: string;

//     constructor() {
//         super();
//     }
// }

// export enum EntityTypes {
//     Master = 1,
//     Reference = 2,
//     Transactional = 3,
//     NonForm = 4,
//     Kpi = 5,
//     KpiParams = 6,
//     ProcessGenerated = 7,
//     View = 8
// }

// export enum EntityTypes {
//     Master = "Master",
//     Reference = "Reference",
//     Transaction = "Transaction",
//     //NonForm = 4,
//     MView = "MView",
//     MView_Params = "MView_Params",
//     ProcessGeneratedEvent = "ProcessGeneratedEvent",
//     View = "View",
//     //Event = 9,
// }

export class StatusContainer {
    status: boolean | undefined;
}

// export class TransactionalDataEntity extends BaseEntity {
//     public identifier: string | null = null;
// }

// export class MutableTransactionEntity extends BaseEntity {
//     identifier: String | null = null;
//     transactionDate?: string | null = null;
//     modifiedDate: string | null = null;
// }

// export class DependencyProperty {
//     public constructor(public idPropertyName: string, public serviceUUID: string, public isDynamic = false) { }
// }

export abstract class BaseDependencyProperty {
    constructor(public idPropertyName: string, public api: DenBaseEnumApi | DenBaseReaderApi | DynamicReaderApi, public resolveWithFullEntity: boolean = false) { }

}

export class DependencyProperty extends BaseDependencyProperty {
    public constructor(public idPropertyName: string, public api: DenBaseEnumApi | DenBaseReaderApi, public resolveWithFullEntity: boolean = false) {
        super(idPropertyName, api, resolveWithFullEntity);
    }
}

export class DynamicDependencyProperty extends BaseDependencyProperty {
    public constructor(public idPropertyName: string, public api: DynamicReaderApi, public schemaName: string, public resolveWithFullEntity: boolean = false) {
        super(idPropertyName, api, resolveWithFullEntity);
    }
}

export type DependencyResolvedData<T> = {
    key: string;
    value: T[];
    api: DenBaseReaderApi | DenBaseEnumApi | DynamicReaderApi;
};

// export class BaseEnumType {
//     id: number | undefined;
//     name: string | undefined;
// }

export class BaseEnumType {
    constructor(public name: string, public label: string) { }
}

export class ServiceRegistry {
    constructor(
        public name: string,
        public value: string,
        public isCacheable: boolean = false,
        public httpRequestType: 'GET' | 'POST',
    ) { }
}

export class QueryResult<T> {
    results: Array<T> | undefined;
    totalResults: number | undefined;
    // constructor(public results: Array<T>, public totalResults: number);
}

export class FilterProperty {
    constructor(public propertyName: string, public operator: FilterOperator, public value1?: any, public value2?: any, public valueList?: Array<any>) {
    }
}
export enum FilterOperator {
    EQUALS = 'EQUALS',
    RANGE = 'RANGE',
    GREATER_THAN = 'GREATER_THAN',
    LESS_THAN = 'LESS_THAN',
    CONTAINS = 'CONTAINS',
    CONTAINS_IGNORE_CASE = 'CONTAINS_IGNORE_CASE',
    NOT_CONTAINS = 'NOT_CONTAINS',
    NOT_CONTAINS_IGNORE_CASE = 'NOT_CONTAINS_IGNORE_CASE',
    STARTS_WITH = 'STARTS_WITH',
    STARTS_WITH_IGNORE_CASE = 'STARTS_WITH_IGNORE_CASE',
    ENDS_WITH = 'ENDS_WITH',
    ENDS_WITH_IGNORE_CASE = 'ENDS_WITH_IGNORE_CASE',
    GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
    LESS_THAN_EQUALS = 'LESS_THAN_EQUALS',
    IN = 'IN',
    EQUALS_IGNORE_CASE = 'EQUALS_IGNORE_CASE',
    IS_NULL = 'IS_NULL',
    IS_NOT_NULL = 'IS_NOT_NULL',
    NOT_EQUAL = 'NOT_EQUAL',
    NOT_EQUAL_IGNORE_CASE = 'NOT_EQUAL_IGNORE_CASE',
    GREATER_THAN_EQUALS_AND_LESS_THAN_EQUALS = 'GREATER_THAN_EQUALS & LESS_THAN_EQUALS',
}
export class SortingOperator {
    static DESCENDING_ORDER = 'DESC';
    static ASCENDING_ORDER = 'ASC';
}


export class SortingField {
    constructor(public field: string, public priority: number) {
    }
}
export class SortingProperty {
    constructor(public sortingFields: Array<SortingField> = new Array<SortingField>(), public sortingOperator: SortingOperator = SortingOperator.ASCENDING_ORDER) {

    }

    addSortingField(sortingField: SortingField): void {
        this.sortingFields.push(sortingField);
    }

    setSortingOperator(sortingOperator: SortingOperator) {
        this.sortingOperator = sortingOperator;

    }
}

export class PageProperties {
    constructor(public pageNumber: number = 1, public pageSize: number = 10) {
    }
    setPageProperties(pageNumber?: number, pageSize?: number) {
        if (pageNumber) {
            this.pageNumber = pageNumber;
        }
        if (pageSize) {
            this.pageSize = pageSize;
        }
    }
}

export enum LogicalOperator {
    AND,
    OR
}

export class AdvancedFilter {
    filterProperties: Array<FilterProperty>;
    sortingProperties: SortingProperty;
    pageProperties: PageProperties;
    attributes: Array<FilterProperty>;

    constructor(includeRemovedItems: string = 'false', public logicalOperator?: LogicalOperator) {
        this.filterProperties = new Array<FilterProperty>();
        this.sortingProperties = new SortingProperty();
        this.pageProperties = new PageProperties();
        this.attributes = new Array<FilterProperty>();
        this.filterProperties.push(new FilterProperty('isRemoved', FilterOperator.EQUALS, includeRemovedItems));
        // will resolve from interceptor from BE
        // const mineId = new SessionStorageService().getSiteId();
        // this.filterProperties.push(new FilterProperty('mineId', FilterOperator.EQUALS, mineId));
    }

    addFilterProperty(filterProperty: FilterProperty): void {
        this.filterProperties.push(filterProperty);
    }

    addAttribute(filterProperty: FilterProperty): void {
        this.attributes.push(filterProperty);
    }
    setLogicalOperator(logicalOperator: LogicalOperator): void {
        this.logicalOperator = logicalOperator;
    }
}

export interface IDuplicateValidationFilter {
    value?: string;
    id?: number;
    url?: string;
    propertyName?: string;
    duplicateMessage?: string;
    advancedFilter?: AdvancedFilter;
}

export class CountContainer {
    count: number | undefined;
}

export class URLType extends BaseEnumType { }

// export class ModuleDeployment extends GlobalBaseEntity {
//     public id: number;
//     public module?: Module;
//     public moduleId: number;
//     public serverUrl: string;
//     public isRemoved: boolean;
//     public urlType?: URLType;
//     public urlTypeId?: number;
//     public name: string;
//     constructor() {
//         super();
//     }
// }


export class PreciseList extends BaseEnumType {
    public isRemoved: boolean | undefined;
    constructor(public name: string, public label: string) {
        super(name, label);
    }
}

export interface IConfig {
    fileUploadUrl2D: string;
    fileUploadUrl3D: string;
    serverURL: string;
    wsBaseURL: string;
    wsGlobalBaseURL:string;
    hybridUserURL: string;
}

export class CacheConstants {
    public static ENTITY_CACHE_VERSIONS_KEY = 'EntityCacheVersions';
    public static ENTITY_CACHE_OBJECT_STORE_KEY = 'EntityCache';
}
export class EntitySchemaDataResponse {
    [property: string]: string | number | boolean | string[] | number[] | EntitySchemaDataResponse[];
}

export interface BasePeriod {
    id: number;
    name: string;
    format: string;
}
