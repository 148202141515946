
export class PdfConstants {
    static FOOTER_LEFT_MARGIN_FOR_TOTAL_PAGES: number = 700;
    static FOOTER_TOP_MARGIN: number = 580;
    static FOOTER_LEFT_MARGIN_FOR_GENERATED_DATE: number = 40;
    static PDF_COLOR_COLUMN_TEXT_IN_WHITE: number[] = [234, 242, 235];
    static PDF_COLOR_COLUMN_TEXT_IN_BLACK: string = '#000000';
}
export class FileConstants {
    static maxFileSize: number = 2000000;
}
export class ThemeConstants {
    static DEFAULT_THEME = 900000000000001;
    static DEFAULT_COLOR = '#FFA700';
    static HIGHLIGHTED_COLOR = '#59f442';
}
export class GenderImagePath {
    static FEMALE_IMAGE_PATH = 'assets/img/female.png';
    static MALE_IMAGE_PATH = 'assets/img/male.png';
}
export class Gender {
    static FEMALE = 900000000000002;
}
export class YearModeFormats {
    static YEAR_MODE_FORMATS = {
        parse: {
            dateInput: 'YYYY',
        },
        display: {
            dateInput: 'YYYY',
        },
    };
}
export class ConfirmationDialogConfig {
    static DIALOG_CONFIG = {
        width: '500px',
        panelClass: 'modal-dialog-wrapper'
    };

}
export class DateTimeFormatConstants {
    static DATE_FORMAT = 'YYYY-MM-DD';
    static DDMMYYYY = 'DD/MM/YYYY';
    static TIME_FORMAT = 'HH:mm:ss';
    static DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
}
export class DialogWidth {
    static MINE_LOCATION_DIALOG_WIDTH = 700;
    static WIDTH = 500;
}
export class ServiceUUID {
    static MINESERVICEUUID: string = 'af3846f9-bd12-4639-babf-56188f7a7beb_1';
}
export enum ControlTypes {
    TEXT_BOX = 1,
    SINGLE_SELECT = 2,
    RADIO_BUTTON = 3,
    CHECK_BOX = 4,
    MULTI_SELECT = 5,
    TEXT_AREA = 6,
    DATE_PICKER = 7,
    TIME_PICKER = 8,
    DATE_TIME_PICKER = 9,
    NUMERIC_BOX = 10,
    LANGUAGE = 11,
    API = 12,
}
export enum FormlyControlTypes {
    INPUT = 'input',
    CHECK_BOX = 'checkbox',
    RADIO_BUTTON = 'radio',
    TEXT_AREA = 'textarea',
    EMAIL = 'email',
    SINGLE_SELECT = 'select',
    MULTI_SELECT = 'multiselect',
    TEXT = 'input',
    DATE = 'datepicker',
    DATE_TIME = 'datetime',
    TIME = 'time',
    BUTTON = 'button',
    INPUT_WITH_NUMBER_TYPE = 'number'
}


export enum WebStorageEnum {
    PREVIOUS_SITE_ID = 'previousSiteId',
    SITE_ID = 'siteId',
    CURRENT_PAGE = 'currentPage',
    DEFAULT_PAGE = 'defaultPage',
    PREVIOUS_MODULE_ID = 'previousModuleId',
    MODULE_ID = 'ModuleId',
    USER_PROFILE = 'userProfile',
    AUTHORIZATION_KEY = 'Authorization',
    DB_KEY = 'dbKey',
    LOGGED_IN = 'logged_in',
    ORG_SETTINGS = 'orgSettings',
    SITE_DETAILS = 'siteDetails',
    CURRENT_ORG_TIME = 'currentOrgTime',
    USER_PERMISSIONS = 'userPermissions',
    BUILD_VERSION = 'buildVersion',
    MODULE_DEPLOYMENT = 'moduleDeployment',
    ENTITY_SCHEMAS = 'entitySchemas',
}

export class HttpHeaderConstants {
    static SITE_ID = 'siteId';
    static AUTHORIZATION_KEY = 'Authorization';
    static IP_ADDRESS = 'ipAddress';
    static USER_AGENT = 'User-Agent';
    static GEO_LOCATION = 'geoLocation';
}

