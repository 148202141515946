import { DevumEntity, BaseEnumType, FilterOperator, FilterProperty, GlobalBaseEntity } from './base.models';

export class ColumnDetails {
    displayName: string;
    propertyName: string;
    filterType?: 'input' | 'select' | 'datepicker' | 'datetimepicker' | 'timepicker' | 'number' | 'searchableSelect';
    defaultFilterOperator?: FilterOperator;
    objPropertyName?: string;
    hasObject?: boolean;
    isFilterable?: boolean;
    showColumnonMain?: boolean;
    showColumnonFlex?: boolean;
    labelColor?: LabelColor;
    labelName?: string;
    id?: number;
    isHyperLink?: boolean;
    dateFormat?: DateFormat;
    isProgressBar?: boolean;
    imageConfig?: ImageConfig;
    dynamicFieldSelectedOptions?: string[];
    referencablePropertyName?: string;
}
export class LabelColor {
    backGroundColor: string;
    labelClass?: string;
}
export class ImageConfig {
    imageURL?: string;
    documentURL?: string;
    defaultImage?: string;
}
export class DateFormat {
    outputFormat: string;
}
export class DeleteSelectionPublisher {
    entityId: number | number[];
    isMultiDelete: boolean;
}
export class IODateFormat {
    static inputFormat: string = 'YYYY-MM-DDTHH:mm:ss+05:30';
    static outputFormat: string = 'YYYY-MM-DD HH:mm:ss';
}
export class CrudModel<T> {
    crudType: number;
    crudInstance: T;
}
export class ParentChild extends BaseEnumType {
    childData: any[] = [];
    showChildren?: boolean;
}
export class UniqueValidator {
    value: string;
    propertyName?: string;
    filterProperties?: FilterProperty[];
    filterOperator?: FilterOperator;
    url?: string;
}
export class FeatureGroup {
    public id: number;
    public name: string;
    public featureGroupIdentifier: string;
    public entityName?: string;
    public operationType?: string;
    public moduleId?: number;
    public isRemoved?: boolean;
    public parentFeatureGroupIdentifier?: string;
    public featureGroupDependencies?: string[];
}
export class DynamicFeatureGroupResult extends FeatureGroup {
    public dynamicFeatureGroupId: number;
    public name: string;
    public description?: string;
    public featureGroupIdentifier: string;
    public parentFeatureGroupIdentifier?: string;
    public isRemoved: boolean;
    public moduleId: number;
    public featureGroupDependencies: Array<string>;
    public operationType?: string;
}
export enum ModeConstants {
    MAIN = 'MAIN',
    FLEX = 'FLEX'
}

export class UserProfile  implements DevumEntity{
    public userAccount?: UserAccount;
		public userAccountId: number;
		public employeeId?: number;
		public name: string;
		public userName: string;
		public emailId?: string;
		public employeeNumber?: string;
		public departmentId?: number;
		public photoName?: string;
		public securityQuestionId?: number;
		public passwordAnswer?: string;
		public mobileNo?: number;
		public preferredLanguageId?: number;
    constructor(){
    }
}
export class UserAccount extends GlobalBaseEntity {
    public userId: number;
		public email: string;
		public mobileNumber?: number;
		public fullName: string;
		public organization?: Organization;
		public organizationId: number;
		public uuid: string;
    constructor(){
        super();
    }
}
export class Organization extends GlobalBaseEntity {
    public organizationName: string;
		public userPoolId?: number;
		public name: string;
    constructor(){
        super();
    }
}