<!DOCTYPE html>
<html>

<head>
    <style type="text/css">
        .rt-grid-wave {
            margin: 40px auto;
            width: 50px;
            height: 30px;
            text-align: center;
            font-size: 10px;
        }

        .rt-grid-wave .rt-grid-rect {
            background-color: #0299d8;
            height: 100%;
            width: 6px;
            display: inline-block;
            -webkit-animation: rt-grid-waveStretchDelay 1.2s infinite ease-in-out;
            animation: rt-grid-waveStretchDelay 1.2s infinite ease-in-out;
        }

        .rt-grid-wave .rt-grid-rect1 {
            -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
        }

        .rt-grid-wave .rt-grid-rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        .rt-grid-wave .rt-grid-rect3 {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }

        .rt-grid-wave .rt-grid-rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        .rt-grid-wave .rt-grid-rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }

        @-webkit-keyframes rt-grid-waveStretchDelay {

            0%,
            40%,
            100% {
                -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
            }

            20% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
            }
        }

        @keyframes rt-grid-waveStretchDelay {

            0%,
            40%,
            100% {
                -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
            }

            20% {
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
            }
        }
    </style>
</head>

<body>
    <div class="rt-grid-wave">
        <div class="rt-grid-rect rt-grid-rect1"></div>
        <div class="rt-grid-rect rt-grid-rect2 rt-mx-5"></div>
        <div class="rt-grid-rect rt-grid-rect3 rt-mr-5"></div>
        <div class="rt-grid-rect rt-grid-rect4 rt-mr-5"></div>
        <div class="rt-grid-rect rt-grid-rect5"></div>
    </div>
</body>

</html>