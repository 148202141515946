import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { Amplify } from '@aws-amplify/core';

// Amplify.configure({
//   Auth: {
//     identityPoolId: 'us-east-1_canOKTFZj',
//     region: 'us-east-1',
//     userPoolId: 'us-east-1_canOKTFZj',
//     userPoolWebClientId: '50j69adcfq9joomb79h8pojb6j'
//   }
// });
// Auth.configure({
//   Auth: {
//     identityPoolId: 'us-east-1_canOKTFZj',
//     region: 'us-east-1',
//     userPoolId: 'us-east-1_canOKTFZj',
//     userPoolWebClientId: '50j69adcfq9joomb79h8pojb6j'
//   }
// }); 

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
