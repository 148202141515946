import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

// @Injectable({
//     providedIn: "root"
// })
@Injectable()
export class WebSocketService {
    ws: WebSocketSubject<string>;
    constructor() { }
    public connect(url: string): WebSocketSubject<string> {
        this.ws = undefined;
        if (!this.ws) {
            this.ws = webSocket({ url: url, deserializer: ({ data }) => data });
        }
        return this.ws;
    }
}
