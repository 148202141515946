export class BaseApiHelper {
    static filterIsRemovedItems<T>(entities: T, includeRemovedItems?: boolean): T {
        if (!entities) { throw new Error("Invalid Input parameters."); }
        let entityList: any = null;
        if (entities instanceof Array) {
            entityList = entities;
            entityList = entityList.filter(function (item: any) {
                if (item.hasOwnProperty("isRemoved")) {
                    if (includeRemovedItems === false) {
                        return item.isRemoved === false;
                    }
                    else {
                        return item;
                    }
                } else {
                    return item
                }
            });
        }
        return entityList;
    }
}