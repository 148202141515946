<div *ngIf="data" class="toast-template-wrapper rt-relative">
  <span class="toast-close-icon">
    <mat-icon class="rt-pull-right rt-font-14" (click)="dismiss()">close</mat-icon>
  </span>
  <div class="row">
    <mat-icon class="rt-pull-left" *ngIf="data?.iconName">{{data?.iconName}}</mat-icon>
    <label class="col-md-10 rt-px-0">{{data?.message}}</label>
  </div>

  <div class="rt-mt-10 rt-ml-20">
    <div class="rt-pull-left rt-mr-5" *ngFor="let action of data?.actions">
      <button class="rt-btn rt-btn-success" (click)="action.callback()">{{action.name}}</button>
    </div>
  </div>
</div>