import * as moment_ from 'moment-timezone';
export class TimeSynchronizer {

    private static timestamp: number;
    private static seedDateTime: string;
    private static moment = moment_;

    /**
     * Initializes TimeSynchronizer with seed datetime 
     * @param seedDateTime is the seed datetime with offset for time synchronizer
     */
    static init(seedDateTime: string) {
        this.seedDateTime = seedDateTime;
        this.timestamp = new Date().getTime();
    }
    /**
     * Get current datetime
     * @return current datetime
     */
    static now() {
        var now = new Date().getTime();
        var result = this.moment(this.seedDateTime).add((now - this.timestamp), "ms").format();
        return result;
    }
}