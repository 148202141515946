import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const lazyroutes: Routes = [
    { path: 'login', loadChildren: () => import('./components/authentication/login/login.module').then(m => m.LoginModule) },
    { path: 'signUp', loadChildren: () => import('./components/authentication/signup/signup.module').then(m => m.SignupModule) },
    { path: '', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(lazyroutes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
