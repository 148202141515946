import { Injectable, Inject } from '@angular/core';
import { HttpClientProvider } from '../abstract/http-client-provider';
import { WebSocketService } from './web-socket/web-socket.service';
import { SessionStorageService } from '../../../../src/app/components/storage/session-storage.service';
import { BaseGlobalWebSocketService } from './base-global-web-socket.service';
import { ConfigService } from '../config-service';

@Injectable()
export class GlobalWebSocketService extends BaseGlobalWebSocketService {

    constructor(@Inject(HttpClientProvider) http: HttpClientProvider, @Inject(WebSocketService) wsService: WebSocketService,
        @Inject(SessionStorageService) sessionStorageService: SessionStorageService, configService: ConfigService) {
        super(http, wsService, sessionStorageService, configService);
    }
}