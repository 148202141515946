import { Observable } from "rxjs";

export abstract class LocalStorageProvider {
    abstract init(databaseName: string, tableName: string): Observable<string>;
    abstract getAll<T>(): Observable<T[]>;
    abstract get<T>(key: string): Observable<T>;
    abstract set<T>(key: string, value: T): Observable<string>;
    abstract remove(key: string): Observable<string>;
    abstract clear(): Observable<string>;
    abstract delete(): Observable<string>;
    abstract close(): void;
}

// export class DBService extends LocalStorageProvider{
//     getAll<T>(): Observable<T[]> {
//         throw new Error("Method not implemented.");
//     }
//     get<T>(key: string): Observable<T> {
//         throw new Error("Method not implemented.");
//     }
//     set<T>(key: string, value: T): Observable<string> {
//         throw new Error("Method not implemented.");
//     }
//     remove(key: string): Observable<string> {
//         throw new Error("Method not implemented.");
//     }
//     clear(): Observable<string> {
//         throw new Error("Method not implemented.");
//     }

// }

// @Injectable({ providedIn: 'root' })
// export class IndexDBManagerService  extends LocalStorageProvider implements OnInit {
//     databaseName!: string;
//     tableName!: string;
//     database: any;
//     indexedDB: any = window.indexedDB;
//     version: any = 1;
//     constructor() { }

//     ngOnInit(): void {
//     }

//     init(databaseName: string, tableName: string) {
//         if (!databaseName) {
//             return new Observable(observer => {
//                 this.indexedDB.databases().then((dbs: IDBIndex[]) => {
//                     const indexDB = dbs.find(db => db.name === CacheConstants.INDEXED_DB_NAME());
//                     databaseName = indexDB ? indexDB.name : CacheConstants.INDEXED_DB_NAME();
//                     this.initDatabase(databaseName, tableName, observer);
//                 }, (error: ErrorEvent) => {
//                     observer.error(error);
//                 });
//             });
//         } else {
//             return new Observable(observer => {
//                 this.initDatabase(databaseName, tableName, observer);
//             });
//         }
//     }

//     initDatabase(databaseName: string, tableName: string, observer: PartialObserver<any>) {
//         this.databaseName = databaseName;
//         this.tableName = tableName || 'EntityCache';
//         const request = this.indexedDB.open(databaseName, this.version);
//         request.onupgradeneeded = (event: any) => {
//             this.tryCreateObjectStore(event, this.tableName);
//         };
//         request.onsuccess = (event: any) => {
//             this.tryCreateObjectStore(event, this.tableName);
//             this.database = event.target.result;
//             observer.next('Database initialized');
//         };
//         request.onerror = () => {
//             observer.error('Database initialization failed');
//         };
//     }

//     private tryCreateObjectStore(event: any, tableName: string) {
//         const db = event.target.result;
//         if (db.objectStoreNames.contains(tableName) === false) {
//             db.createObjectStore(tableName);
//         }
//     }
//     private getValuesFromStore(database: any, tableName: string, observer: PartialObserver<any>) {
//         //https://googlechrome.github.io/samples/idb-getall/
//         const transaction = database.transaction([tableName], 'readwrite');
//         const objectStore = transaction.objectStore(tableName);
//         if ('getAll' in objectStore) {
//             // IDBObjectStore.getAll() will return the full set of items in our store.
//             objectStore.getAll().onsuccess = function (event: any) {
//                 observer.next(event.target.result);
//             };
//         } else {
//             // Fallback to the traditional cursor approach if getAll isn't supported.
//             const data: any[] = [];
//             objectStore.openCursor().onsuccess = function (event: any) {
//                 const cursor = event.target.result;
//                 if (cursor) {
//                     data.push(cursor.value);
//                     cursor.continue();
//                 } else {
//                     observer.next(data);
//                 }
//             };
//         }
//     }
//     getAll<T>() :Observable<T>{
//         return new Observable(observer => {
//             if (!this.database) {
//                 this.init(this.databaseName, this.tableName).subscribe(() => {
//                     this.getValuesFromStore(this.database, this.tableName, observer);
//                 });
//             } else {
//                 this.getValuesFromStore(this.database, this.tableName, observer);
//             }
//         });
//     }
//     private getValueFromStore(database: any, tableName: string, key: string, observer: PartialObserver<any>) {
//         const transaction = database.transaction([tableName], 'readwrite');
//         const objectStore = transaction.objectStore(tableName);
//         const request = objectStore.get(key);
//         request.onsuccess = (event: any) => {
//             observer.next(event.target.result);
//         };
//         request.onerror = (error) => {
//             observer.error(error);
//         };
//     }
//     get<T>(key: string) :Observable<T> {
//         return new Observable(observer => {
//             if (!this.database) {
//                 this.init(this.databaseName, this.tableName).subscribe(() => {
//                     this.getValueFromStore(this.database, this.tableName, key, observer);
//                 });
//             } else {
//                 this.getValueFromStore(this.database, this.tableName, key, observer);
//             }
//         });
//     }

//     set<T>(key: string, value: T) {
//         return new Observable(observer => {
//             if (this.database) {
//                 const transaction = this.database.transaction([this.tableName], 'readwrite');
//                 const objectStore = transaction.objectStore(this.tableName);
//                 const request = objectStore.put(value, key);
//                 request.onsuccess = () => {
//                     observer.next('Successfully set data to IndexedDb');
//                 };
//                 request.onerror = () => {
//                     observer.error('Error while set data to IndexedDb');
//                 };
//             } else {
//                 console.log('database not found to set ' + ' in indexdb');
//             }
//         });
//     }

//     remove(key: string) {
//         return new Observable(observer => {
//             const transaction = this.database.transaction([this.tableName], 'readwrite');
//             const objectStore = transaction.objectStore(this.tableName);
//             const request = objectStore.delete(key);
//             request.onsuccess = () => {
//                 observer.next('Successfully deleted from IndexedDb');
//             };
//             request.onerror = () => {
//                 observer.error('Error while deleting data from IndexedDb');
//             };
//         });
//     }

//     clear() {
//         return new Observable(observer => {
//             const transaction = this.database.transaction([this.tableName], 'readwrite');
//             const objectStore = transaction.objectStore(this.tableName);
//             const request = objectStore.clear();
//             request.onsuccess = () => {
//                 observer.next('Successfully cleared data from table :' + this.tableName + ' in IndexedDb');
//             };
//             request.onerror = () => {
//                 observer.error('Error while clear data from table :' + this.tableName + ' in IndexedDb');
//             };
//         });
//     }

//     delete() {
//         return new Observable(observer => {
//             const request = window.indexedDB.deleteDatabase(this.databaseName);
//             request.onsuccess = (_event) => {
//                 observer.next('Successfully deleted database ' + this.databaseName);
//             };
//             request.onerror = (_error) => {
//                 observer.error('Error deleteing database ' + this.databaseName + ' from IndexedDb');
//             };
//             // observer.next(request);
//         });
//     }
// }
