import { Injectable } from '@angular/core';
import { IConfig } from '../models/base.models';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private config: IConfig;
  constructor() { }

  setConfig(config: IConfig) {
    this.config = config;
  }

  getConfig(): IConfig {
    return this.config;
  }
}
