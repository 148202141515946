import { Injectable, Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogProvider } from "libs/dc-core/providers/abstract/dialog-provider";
import { ReactivateEntityDialogComponent } from "src/app/components/components-library/reactivate-entity-dialog/reactivate-entity-dialog.component";
import { DialogComponent } from "../../components/dialog/dialog.component";
import { ConfirmationDialogConfig } from "../../constant";

@Injectable({ providedIn: 'root' })
export class DialogService extends DialogProvider {


   public _dialog = this.injector.get(MatDialog);

    constructor(public injector: Injector) {
        super();
    }

    showErrorDialog<T>(error: any, next?: (value: T) => void) {
        const dialogRef = this._dialog.open(ReactivateEntityDialogComponent, {
            width: '750px',
            panelClass: 'modal-dialog-wrapper',
            data: error,
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe(next);
    }

    showDeleteDialog<T>(data: any, next?: (value: T) => void) {
        const dialogRef = this._dialog.open(DialogComponent, {
            ...{
                data: data,
                autoFocus: false
            }, ...ConfirmationDialogConfig.DIALOG_CONFIG
        });
        dialogRef.afterClosed().subscribe(next);
    }
    

}
  
