import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastServiceProvider } from 'libs/dc-core/providers/abstract/toast-service-provider';
import { ToastComponent } from '../../components/toast/toast.component';
// import { Dir } from '@angular/cdk/bidi';

@Injectable()
export class ToastService extends ToastServiceProvider {
    matSnackBarConfig: MatSnackBarConfig;

    private autoHide = 3000;
    // private addExtraClass = false;
    private horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    private verticalPosition: MatSnackBarVerticalPosition = 'top';

    // constructor(private snackBar: MatSnackBar, private dir: Dir) { }
    constructor(private readonly _snackBar: MatSnackBar, private readonly zone: NgZone) {
        super();
    }

    private _createConfig(autoHide?: number) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = autoHide || this.autoHide;
        // config.panelClass = this.addExtraClass ? ['demo-party'] : undefined;
        // config.direction = this.dir.value;
        return config;
    }

    private _open(message: string, iconName?: string, actions?: { name: string, cssClass: string, callback: () => void; }[],
        customConfig?: MatSnackBarConfig, autoHide?: number) {

        const config = this._createConfig(autoHide);
        Object.assign(config, customConfig);
        config.data = { message: message, iconName: iconName, actions: actions };
        // this.snackBar.open(message, action ? action : undefined, config);
        this.zone.run(() => {
            this._snackBar.openFromComponent(ToastComponent, config);
        });
    }

    showSuccess(successMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: MatSnackBarConfig) {
        config = config ? config : { panelClass: 'rt-success' };
        this._open(successMessage, 'done', action, config);
    }

    showError(errorMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: MatSnackBarConfig) {
        config = config ? config : { panelClass: 'rt-error' };
        
        const autoHide = 10000;
        this._open(errorMessage, 'error', action, config,autoHide);
    }

    showInfo(infoMessage: string, action?: { name: string, cssClass: string, callback: () => void; }[], config?: MatSnackBarConfig) {
        config = config ? config : { panelClass: 'rt-info' };
        this._open(infoMessage, 'warning', action, config);
    }
    customToast(message: string, iconName?: string, actions?: { name: string, cssClass: string, callback: () => void; }[], customConfig?: MatSnackBarConfig) {
        this._open(message, iconName, actions, customConfig);
    }
}
