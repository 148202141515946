import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

//https://stackoverflow.com/a/43391156/1459516
 @Injectable()
export abstract class HttpClientProvider {

    abstract get<T>(constructedUrl: string, params?: { [param: string]: any; }): Observable<T>;

    abstract getObject(constructedUrl: string): Observable<Object>;

    abstract post<T>(constructedUrl: string, body: any | null, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<any>;

    abstract put<T>(constructedUrl: string, entity: T): Observable<T>;

    abstract delete<T>(constructedUrl: string): Observable<T>;
}