import * as moment_ from 'moment';
import { TimeSynchronizer } from "./time-synchronizer";
export class OrganizationDate {
    public static orgTimezone: string;
    public static moment = moment_;

    /**
     * Initialize OrgDate with timezone
     */
    static init(timezone: string) {
        this.orgTimezone = timezone;
    }

    /*
    * To get the organization time zone.
    */
    static getOrgTimeZone() {
        return this.orgTimezone || null;
    }

    /**
     * Get org current date time in org timezone in ISO format with offset e.g.: 2021-01-29T00:00:00+05:30
     * @return current time in org timezone
     */
    static now() {
        return TimeSynchronizer.now();
    }

    /**
     * Get org current time in org timezone
     * @return current time in org timezone
     */
    static timeNow() {
        return this.now().slice(11, 19) || null;
    }

    /**
     * Get beginning of today in UTC.
     * Ex: If the today's date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns UTC date time at the beginning of today in string fromat 
     */
    static getBeginingOfTodayInUTC() {
        var now = TimeSynchronizer.now();
        return this.getBeginningOfDayInUTC(now);
    }

    /**
     * Get beginning of day in UTC for given dateTimeWithOffset.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns UTC date time at the beginning of day for given date in string fromat 
     */
    static getBeginningOfDayInUTC(dateTimeWithOffset: string) {
        var startOfDay = dateTimeWithOffset.slice(0, 11) + "00:00:01";
        return this.moment(startOfDay).toISOString();
    }

    /**
     * Get end of today in UTC.
     * Ex: If the today's date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-28T18:30:01.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns UTC date time at the end of today in string fromat 
     */
    static getEndOfTodayDayInUTC() {
        var now = TimeSynchronizer.now();
        return this.getEndOfDayInUTC(now);
    }

    /**
     * Get end of day in UTC for given dateTimeWithOffset.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 the return value will be 2021-01-29T18:29:59.000Z
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @returns uTC date time at the end of day for given date in string fromat 
     */
    static getEndOfDayInUTC(dateTimeWithOffset: string) {
        var endOfDay = dateTimeWithOffset.slice(0, 11) + "23:59:59";
        return this.moment(endOfDay).toISOString();
    }

    /**
     * Get time in UTC for given time.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 and time is 13:00:00 then the return value will be 07:30:00
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @param time in string format. Ex: 13:00:00
     * @returns UTC time for the org time
     */
    static getTimeInUTCForOrgTime(time: string) {
        var now = TimeSynchronizer.now();
        return this.getTimeInUTCForTimeZoneTime(now, time);
    }

    /**
     * Get time in UTC for given dateTimeWithOffset and time.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 and time is 13:00:00 then the return value will be 07:30:00
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @param time in string format. Ex: 13:00:00
     * @returns UTC time for the org time
     */
    static getTimeInUTCForTimeZoneTime(dateTimeWithOffset: string, time: string) {
        var endOfDay = dateTimeWithOffset.slice(0, 11) + time + dateTimeWithOffset.slice(19, 25);
        return this.moment(endOfDay).toISOString().slice(11, 19);
    }
    /**
     * Converts the given date to the start/end of the day
     * @returns UTC time for the give datetime
     */
    static convertOrganizationDateTimeToUTCDateTime(dateTimeWithOffset: string) {
        return OrganizationDate.moment(dateTimeWithOffset).toISOString();
    }

    /**
     * Converts UTC time to org time.
     * Ex: If the passed in UTC time is 13:00:00 then the return value will be 07:30:00         
     * @param time in string format. Ex: 13:00:00
     * @returns org time for the UTC time
     */
    static convertUTCTimeToOrgTime(utcTime: string) {
        var now = TimeSynchronizer.now();
        return this.convertUTCTimeToTimezoneTime(now, utcTime, this.orgTimezone);
    }

    /**
     * Converts UTC time to org time.
     * Ex: If the passed date is 2021-01-29T00:00:00+05:30 and UTC time is 13:00:00 then the return value will be 07:30:00
     * @param dateTimeWithOffset in string format. Ex: 2021-01-29T00:00:00+05:30
     * @param time in string format. Ex: 13:00:00
     * @returns org time for the UTC time
     */
    static convertUTCTimeToTimezoneTime(dateTimeWithOffset: string, utcTime: string, orgTimezone: string) {
        var isoDateTime = dateTimeWithOffset.slice(0, 11) + utcTime + ".000Z";
        var date: any = this.moment(isoDateTime);
        //return date.tz(orgTimezone).format("HH:MM:SS");
        return date.tz(orgTimezone).format().slice(11, 19);
    }

    /**
     * Converts ISO UTC date time to org date time.
     * Ex: If the passed date is 2017-01-02T10:31:39.716Z and the org time zone is Africa/Johannesburg then 
     * the return value will be 2017-01-02T12:31:39+02:00
     * @param isoDateTime in string format. Ex: 2015-02-23T04:02:03.000Z
     * @returns org date time for the UTC date time
     */
    static convertIsoUtcDateTimeToOrgDateTime(isoDateTime: string) {
        return this.moment(isoDateTime).format() || null;
    }
}