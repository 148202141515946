import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import * as moment_ from 'moment-timezone';
import { SessionStorageService } from '../../../src/app/components/storage/session-storage.service';
import { HttpHeaderConstants, HttpRequestTypes } from '../constants/http-constants';
import { AdvancedFilter, GlobalBaseEntity } from '../models/base.models';

export class HttpRequestInterceptor {
    static moment = moment_;
    private static sessionStorageService: SessionStorageService;

    static checkForTrackableEntity(element: any, operation: string) {
        if (element instanceof Object) {
            if (this.isBaseEntity(element)) {
                this.prepareEntity(element, operation);
            } else {
                for (const i in element) {
                    if (element.hasOwnProperty(i)) {
                        this.checkForTrackableEntity(element[i], operation);
                    }
                }
            }
        }
    }

    static intercept(element: any, operation: string) {
        if (element && (operation === HttpRequestTypes.POST || operation === HttpRequestTypes.PUT)) {
            this.cleanUpEntity(element);
            if (this.isBaseEntity(element)) {
                this.prepareEntity(element, operation);
                return element;
            } else if (element.constructor === Array) {
                for (let i = 0; i < element.length; i++) {
                    this.checkForTrackableEntity(element[i], operation);
                }
            } else {
                for (const propertyName in element) {
                    if (element.hasOwnProperty(propertyName) && element[propertyName] instanceof Object) {
                        if (this.isBaseEntity(element[propertyName])) {
                            this.prepareEntity(element[propertyName], operation);
                        } else if (element[propertyName].constructor === Array) {
                            for (let i = 0; i < element[propertyName].length; i++) {
                                if (element[propertyName][i] instanceof Object) {
                                    if (this.isBaseEntity(element[propertyName][i])) {
                                        this.prepareEntity(element[propertyName][i], operation);
                                    } else {
                                        for (const property in element[propertyName][i]) {
                                            if (element[propertyName][i].hasOwnProperty(property) && element[propertyName][i][property] instanceof Object) {
                                                if (this.isBaseEntity(element[propertyName][i][property])) {
                                                    this.prepareEntity(element[propertyName][i][property], operation);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return element;
    }

    static cleanUpEntity(entity: any) {
        delete entity.$$hashKey;
        delete entity.$edit1;
        if (entity instanceof AdvancedFilter) {
            if (typeof entity.pageProperties.pageSize === 'string') {
                entity.pageProperties.pageSize = parseInt(entity.pageProperties.pageSize, 10);
            }
        }

    }

    static prepareEntity(element: any, operation: string) {
        if (operation === HttpRequestTypes.POST) {
            this.prepareEntityForPost(element);
        } else if (operation === HttpRequestTypes.PUT) {
            this.prepareEntityForPut(element);
        }

        // if (this.isTransactionalEntity(element)) {
        //     element.identifier = IdentityGenerator.guid();
        //     if (element.transactionDate) {
        //         element.transactionDate = this.moment(element.transactionDate).toISOString();
        //     } else {
        //         element.transactionDate = this.moment().toISOString();
        //     }
        // } else if (this.isMutableTransactionalEntity(element)) {
        //     element.identifier = IdentityGenerator.guid();
        //     if (element.transactionDate) {
        //         element.transactionDate = this.moment(element.transactionDate).toISOString();
        //     } else {
        //         element.transactionDate = this.moment().toISOString();
        //     }
        // }
    }

    static prepareEntityForPost(element: { identifier?: string; transactionDate?: any; createdBy?: any; createDate?: any; modifiedDate?: any; siteId?: any; }) {
        const userProfile = this.sessionStorageService.getUserProfile();
        if (userProfile && element.hasOwnProperty('createdBy')) {
            element.createdBy = userProfile.userInfo.userAccount.id;
        }
        if (element.hasOwnProperty('createDate')) {
            element.createDate = this.moment().toISOString();
        }
        //element.createDate = this.moment().toISOString();
        //element.modifiedDate = this.moment().toISOString();
        //element.siteId = this.sessionStorageService.getCognitoToken();
    }

    static prepareEntityForPut(element: { identifier?: string; transactionDate?: any; createDate?: any; siteId?: any; modifiedDate?: any; }) {
        element.createDate = this.moment(element.createDate).toISOString();
        //element.siteId = this.sessionStorageService.getCognitoToken();
        element.modifiedDate = this.moment().toISOString();

    }

    static getCurrentIsoDateTimeWithLocalTimeZone() {
        const now = new Date();
        const isoFormatWithLocalTimeZone = now.toISOString().substr(0, 10) + ' ' + now.toTimeString().substr(0, 8);
        return isoFormatWithLocalTimeZone;
    }

    static isBaseEntity(entity: { hasOwnProperty: { (arg0: string): boolean; (arg0: string): boolean; (arg0: string): boolean; }; }) {
        return (entity instanceof GlobalBaseEntity) ||
            (entity.hasOwnProperty('createDate') && entity.hasOwnProperty('createdBy'));
    }

    // static isTransactionalEntity(entity: { identifier?: string; transactionDate?: any; hasOwnProperty?: any; }) {
    //     return (entity instanceof TransactionEntity) || (entity.hasOwnProperty('identifier') && entity.hasOwnProperty('transactionDate'));
    // }

    // static isMutableTransactionalEntity(entity: { identifier?: string; transactionDate?: any; hasOwnProperty?: any; }) {
    //     return (entity instanceof MutableTransactionEntity) || (entity.hasOwnProperty('identifier') && entity.hasOwnProperty('transactionDate') && entity.hasOwnProperty('modifiedDate'));
    // }

    static setHttpHeaders<T>(req: HttpRequest<T>, baseUrl: string, isUserInLoginPage: boolean, sessionStorageService: SessionStorageService, router: Router): HttpRequest<T> {
        let headers = new HttpHeaders(), authorization: string | string[], siteId: string | string[];
        this.sessionStorageService = sessionStorageService;
        if (req.responseType !== 'blob') {
            authorization = sessionStorageService.getCognitoToken();
        }
        if (authorization) {
            headers = headers.append(HttpHeaderConstants.API_KEY, authorization);

        }
        const dcRegion = sessionStorageService.getDataCenterRegion();
        const isAdmin = sessionStorageService.getUserInfo()?.isReactoreAdmin;

        if (isAdmin && dcRegion && router?.url === '/console') {
            headers = headers.append(HttpHeaderConstants.DC_REGION, dcRegion);
        }

        // If the authenticationKey is not available, then the user is not logged-in;
        // System will take it to LOGIN page again.
        if (!authorization && !isUserInLoginPage && req.responseType !== 'blob') { location.href = ''; return req; }

        let url = '';

        // todo: url is appending twice.
        if (!req.url.match(/^(http:\/\/|https:\/\/|ws:\/\/|wss:\/\/)/)) {
            url = baseUrl + req.url;
        } else {
            url = req.url;
        }
        const body = this.intercept(req.body, req.method);
        const apiReq = req.clone({ url: url, headers: headers, body: body });

        return apiReq;
    }
}
