import { Inject, Injectable } from '@angular/core';
import { Observable, PartialObserver, zip } from 'rxjs';
import { DenBaseEnumApi } from 'src/app/shared/base-apis/den-base-enum-api';
import { DenBaseReaderApi } from 'src/app/shared/base-apis/den-reader-base-api';
import { DynamicReaderApi } from 'src/app/shared/base-apis/dynamic-reader-api';
import { BaseDependencyProperty, DependencyResolvedData, DynamicDependencyProperty, DependencyProperty } from '../models/base.models';
import { ForeignKeyEntityResolverProvider } from './abstract/foreign-key-entity-Provider';
import { LocalStorageProvider } from './abstract/indexdb-manager-service-provider';
import { ConfigService } from './config-service';



@Injectable()
export class DependencyService extends ForeignKeyEntityResolverProvider {
    baseUrl: string = this.configService.getConfig().serverURL;
    constructor(@Inject(LocalStorageProvider) private indexedDbService: LocalStorageProvider,
        @Inject(ConfigService) private configService: ConfigService,
        // @Inject(HttpClientProvider) private http: HttpClientProvider
    ) {
        super();
    }
    checkDependenciesAndResolveForSingleEntity<T>(entity: T, dependencies: BaseDependencyProperty[]): Observable<T> {
        return new Observable(observer => {
            if (!dependencies || dependencies.length === 0) {
                observer.next(entity);
                return;
            } else {
                this.getDependenciesData(dependencies).subscribe((dependenciesData) => {
                    this.recursivelyPopulateDependenciesData(entity, dependenciesData);
                    observer.next(entity);
                }, () => {
                    observer.next(entity);
                });
            }
        });
    }
    checkDependenciesAndResolve<T>(entities: T, dependencies: BaseDependencyProperty[]): Observable<T> {
        return new Observable(observer => {
            if (!dependencies || dependencies.length === 0) {
                observer.next(entities);
                return;
            } else {
                this.getDependenciesData(dependencies).subscribe((dependenciesData) => {
                    this.resolveDependencies(entities, dependenciesData);
                    observer.next(entities);
                }, () => {
                    observer.next(entities);
                });
            }
        });
    }

    

    // private getByServiceUUID<T>(serviceUUID: string, isDynamic?: boolean, payload: any = null): Observable<T> {
    //     return new Observable(observer => {
    //         this.indexedDbService.get(serviceUUID).subscribe((data: any) => {
    //             if (data) {
    //                 this.constructCachedData(isDynamic, observer, serviceUUID, JSON.parse(JSON.stringify(data)));
    //             } else {
    //                 this.constructDataForServiceUUID(isDynamic, observer, serviceUUID, payload);
    //             }
    //         });
    //     });
    // }
    // private constructDataForServiceUUID(isDynamic: boolean | undefined, observer: PartialObserver<any>, serviceUUID: string, payload: any) {
    //     if (isDynamic) {
    //         this.isMasterOrReferenceEntity(serviceUUID).subscribe(data => {
    //             const url = `${this.baseUrl}instanceDetails/${serviceUUID}`;
    //             this.onGetByServiceUUIDSuccess(url, data, 'GET', payload, observer);
    //         });
    //     } else {
    //         this.resolveDependencyURL(serviceUUID)
    //             .subscribe(
    //                 (response: ServiceRegistry) => this.onGetByServiceUUIDSuccess(response.value, response.isCacheable, response.httpRequestType, payload, observer),
    //                 (error: Error) => console.error(error));
    //     }
    // }
    // private isMasterOrReferenceEntity(entityName: string): Observable<boolean> {
    //     return new Observable(observer => {
    //         const entitySchemaData = localStorage.getItem(WebStorageEnum.ENTITY_SCHEMAS);
    //         if (!entitySchemaData) {
    //             this.http.get<EntitySchema[]>(this.baseUrl + WebStorageEnum.ENTITY_SCHEMAS).subscribe(
    //                 (schemaResults: EntitySchema[]) => {
    //                     // this.indexdbService.set(this.baseUrl + WebStorageEnum.ENTITY_SCHEMAS, schemaResults).subscribe();
    //                     localStorage.setItem(WebStorageEnum.ENTITY_SCHEMAS, JSON.stringify(schemaResults));
    //                     const cachableSchema = schemaResults.find(schema => schema.name === entityName && (schema.entityTypeId === EntityTypes.Master || schema.entityTypeId === EntityTypes.Reference));
    //                     observer.next(cachableSchema ? true : false);
    //                 });
    //         } else {
    //             const entitySchemas: EntitySchema[] = JSON.parse(entitySchemaData);
    //             const cachableSchema = entitySchemas.find(schema => schema.name === entityName && (schema.entityTypeId === EntityTypes.Master || schema.entityTypeId === EntityTypes.Reference));
    //             observer.next(cachableSchema ? true : false);
    //         }
    //     });
    // }

    // private constructCachedData(isDynamic: boolean | undefined, observer: PartialObserver<any>, serviceUUID: string, data: any) {
    //     if (isDynamic) {
    //         this.isMasterOrReferenceEntity(serviceUUID).subscribe(res => {
    //             const url = `${this.baseUrl}instanceDetails/${serviceUUID}`;
    //             this.onGetByServiceUUIDSuccess(url, res, 'GET', null, observer);
    //         });
    //     } else {
    //         this.onGetByServiceUUIDSuccess(data.value, true, 'GET', null, observer);
    //     }
    // }

    // private onGetByServiceUUIDSuccess<T>(url: string, isCacheable: boolean, httpRequestType: 'GET' | 'POST' = 'GET', body: any = null, observer: PartialObserver<T>) {
    //     this.indexedDbService.get(url).subscribe((data: any) => {
    //         if (data) {
    //             observer.next(JSON.parse(JSON.stringify(data)));
    //         } else {
    //             if (httpRequestType === 'GET') {
    //                 this.onGetData(url, isCacheable, observer);
    //             } else if (httpRequestType === 'POST') {
    //                 this.onPostData(url, body, observer);
    //             } else {
    //                 observer.error(`Http Request type is not supported`);
    //             }
    //         }
    //     });

    // }
    // private onGetData(url: string, isCacheable: boolean, observer: PartialObserver<any>) {
    //     this.http.get(url).subscribe(data => {
    //         if (isCacheable) {
    //             this.indexedDbService.set(url, JSON.parse(JSON.stringify(data))).subscribe();
    //         }
    //         observer.next(data);
    //     }, (error) => observer.error(error));
    // }

    // private onPostData<T>(url: string, body: any, observer: PartialObserver<T>) {
    //     if (!body) { observer.error(`Payload is missing for ${url}`); }
    //     this.http.post(url, body).subscribe(data => {
    //         observer.next(data);
    //     }, (error) => observer.error(error));
    // }

    private resolveDependencies<T>(entities: T, dependenciesData: DependencyResolvedData<T>[]) {
        if (entities instanceof Array) {
            entities.forEach(entity => {
                this.recursivelyPopulateDependenciesData(entity, dependenciesData);
            });
        } else {
            this.recursivelyPopulateDependenciesData(entities, dependenciesData);
        }

        return entities;
    }

    private isDependency<T>(i: string, dependenciesData: Array<DependencyResolvedData<T>>) {
        return dependenciesData.find((item) => (item.key === i));
    }

    private recursivelyPopulateDependenciesData<T>(entity: any, dependenciesData: Array<DependencyResolvedData<T>>) {
        for (const i in entity) {
            if (entity.hasOwnProperty(i)) {
                if (this.isDependency(i, dependenciesData)) {
                    if (entity[i] instanceof Array) {
                        const propertyArrayData: T[] = [];
                        entity[i].forEach((nestedEntity: any) => {
                            propertyArrayData.push(this.getValueForDependency(dependenciesData, i, nestedEntity)[0]);
                        });
                        entity[this.getObjectPropertyName(i)] = propertyArrayData;
                    } else {
                        entity[this.getObjectPropertyName(i)] = this.getValueForDependency(dependenciesData, i, entity[i])[0];
                    }
                } else if (entity[i] instanceof Array) {
                    entity[i].forEach((nestedEntity: any) => {
                        this.recursivelyPopulateDependenciesData(nestedEntity, dependenciesData);
                    });
                } else if (entity[i] instanceof Object) {
                    this.recursivelyPopulateDependenciesData(entity[i], dependenciesData);
                }
            }
        }
    }

    protected getValueForDependency<T extends { name?: string; id?: string }>(dependenciesData: Array<DependencyResolvedData<T>>, key: string, value: any) {
        if (!dependenciesData || !key) { throw new Error('Invalid input parameters.'); }

        if (!value) { return []; }

        const res = dependenciesData.find((item) => (item.key === key)).value || [];

        if (res.length === 0) {
            return [];
        }
        return res.filter(item => item.name == value);
    }

    protected getObjectPropertyName(propertyName: string): string {
        return propertyName.substring(0, propertyName.length - 4);
    }


    private getDependenciesData<T>(dependencies: BaseDependencyProperty[]): Observable<DependencyResolvedData<T>[]> {
        return new Observable(observer => {
            const uniqueDependencies: BaseDependencyProperty[] = this.getUniqueListBy(dependencies, 'api');
            const dependenciesData: DependencyResolvedData<T>[] = [];
            this.getUniqueDependenciesData<T>(uniqueDependencies)
                .subscribe((resolvedDependent: DependencyResolvedData<T>[]) => {
                    dependencies.forEach(dep => {
                        dependenciesData.push({
                            key: dep.idPropertyName,
                            value: resolvedDependent.find(res => res.api.name === dep.api.name).value || [],
                            api: dep.api
                        });
                    });
                    observer.next(dependenciesData);
                });
        });
    }

    
    private getUniqueDependenciesData<T>(dependencies: BaseDependencyProperty[] | DynamicDependencyProperty[]): Observable<DependencyResolvedData<T>[]> {
        return new Observable(observer => {
            const dependencyData: Observable<any>[] = new Array();
            dependencies.forEach((dep: BaseDependencyProperty) => {
                dependencyData.push(this.getDependencyResolver(dep));
            });
            zip(...dependencyData).subscribe((data: DependencyResolvedData<T>[]) => observer.next(data));
        });
    }

    private getDependencyResolver<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        if (dep instanceof DependencyProperty) {
            if (dep.api instanceof DenBaseReaderApi) {
                return this.getReaderApiData(dep);
            } else if (dep.api instanceof DenBaseEnumApi) {
                return this.getEnumApiData(dep);
            } else {
                throw new Error(`Dependency API is not defined`);
            }
        // } else if (dep instanceof DynamicDependencyProperty) {
        //     if (dep.api instanceof DynamicReaderApi) {
        //         return this.getDynamicReaderApiData(dep);
        //     } else {
        //         throw new Error(`Dependency API is not defined`);
        //     }
        }else {
            throw new Error(`Invalid dependency property type, should be of BaseDependencyProperty`);
        }
    }

    public getDependencyData<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        return this.getDependencyResolver(dep);
    }

    private getEnumApiData<T>(dep: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        return new Observable(observer => {
            if (dep.api instanceof DenBaseEnumApi) {
                return observer.next({ key: dep.api.name, value: dep.api.getList() as unknown as T[], api: dep.api });
            }
        });
    }

    // private getDynamicReaderApiData<T>(dep: DynamicDependencyProperty): Observable<DependencyResolvedData<T>> {
    //     return new Observable(observer => {
    //         if (dep.api instanceof DynamicReaderApi) {
    //             // DynamicFormsReaderApi.
    //             dep.api.getCollectionList<T>(dep.schemaName, [])
    //                 .subscribe(
    //                     (d: T[]) => {
    //                         return observer.next({ key: dep.schemaName, value: d, api: dep.api });
    //                     },
    //                     () => observer.next({ key: dep.schemaName, value: [], api: dep.api }));
    //         }
    //     });
    // }

    private getUniqueListBy<T extends { [key: string]: any; }>(arr: T[], key: string) {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    }

    private getReaderApiData<T>(dependency: BaseDependencyProperty): Observable<DependencyResolvedData<T>> {
        return new Observable(observer => {
            if (dependency.api instanceof DenBaseReaderApi) {
                const readerApi: DenBaseReaderApi = dependency.api;
                this.indexedDbService.get<T[]>(readerApi.name).subscribe((data: T[]) => {
                    if (data) {
                        observer.next({ key: readerApi.name, value: JSON.parse(JSON.stringify(data)), api: readerApi });
                    } else {
                        this.getDependencyDataFromDb(dependency, observer);
                    }
                }, () => {
                    observer.next({ key: readerApi.name, value: [], api: readerApi });
                });
            } else {
                observer.next({ key: dependency.api.name, value: [], api: dependency.api });
            }

        });
    }
    private getDependencyDataFromDb<T>(dependency: BaseDependencyProperty, observer: PartialObserver<DependencyResolvedData<T>>) {
        if (dependency.api instanceof DenBaseReaderApi) {
            const readerApi: DenBaseReaderApi = dependency.api;
            const isCacheable = readerApi.isCacheable;
            if (dependency.resolveWithFullEntity === true) {
                this.resolveGetList<T>(readerApi, isCacheable, observer);
            } else {
                this.resolvePreciseList<T>(readerApi, isCacheable, observer);
            }
        }
    }

    private resolveGetList<T>(readerApi: DenBaseReaderApi, isCacheable: boolean, observer: PartialObserver<DependencyResolvedData<T>>) {
        readerApi.getList([], true)
            .subscribe(
                (data: T[]) => {
                    if (isCacheable) {
                        this.indexedDbService.set(readerApi.name, JSON.parse(JSON.stringify(data))).subscribe;
                    }
                    observer.next({ key: readerApi.name, value: data, api: readerApi });
                },
                () => {
                    observer.next({ key: readerApi.name, value: [], api: readerApi });
                });
    }

    private resolvePreciseList<T>(readerApi: DenBaseReaderApi, isCacheable: boolean, observer: PartialObserver<DependencyResolvedData<T>>) {
        readerApi.getPreciseList(true)
            .subscribe(
                (data: T[]) => {
                    if (isCacheable) {
                        this.indexedDbService.set(readerApi.name, JSON.parse(JSON.stringify(data))).subscribe;
                    }
                    observer.next({ key: readerApi.name, value: data, api: readerApi });
                },
                () => {
                    observer.next({ key: readerApi.name, value: [], api: readerApi });
                });
    }

    // private getDependencyUrl(dependency: BaseDependencyProperty): Observable<DependencyHttpDetail> {
    //     return new Observable(observer => {
    //         this.CheckInIndexDB(dependency, observer);
    //     });
    // }
    // private checkIsMasterOrReference(dependency: BaseDependencyProperty, observer: PartialObserver<DependencyHttpDetail>) {
    //     this.isMasterOrReferenceEntity(dependency.serviceUUID).subscribe((isCacheable: boolean) => {
    //         observer.next(
    //             {
    //                 key: dependency.idPropertyName,
    //                 url: `${this.baseUrl}instanceDetails/${dependency.serviceUUID}`,
    //                 api: dependency.serviceUUID,
    //                 isCacheable: isCacheable
    //             });
    //     });
    // }
    // private CheckInIndexDB(dependency: BaseDependencyProperty, observer: PartialObserver<DependencyHttpDetail>) {
    //     this.indexedDbService.get(dependency.api.).subscribe((result: any) => {
    //         if (!result) {
    //             if (dependency.isDynamic) {
    //                 this.checkIsMasterOrReference(dependency, observer);

    //             } else {
    //                 this.resolveDependencyURL(dependency.serviceUUID).subscribe((response: any) => {
    //                     if (response && response.isCacheable) {
    //                         this.getAndSetServiceRegistryInIndexDB(response, observer, dependency);
    //                     } else {
    //                         observer.next({
    //                             key: dependency.idPropertyName,
    //                             url: response.value,
    //                             httpRequestType: response.httpRequestType,
    //                             api: dependency.serviceUUID,
    //                             isCacheable: false
    //                         });
    //                     }
    //                 }, (error: Error) => observer.error(error));
    //             }
    //         } else {
    //             observer.next({ key: dependency.idPropertyName, url: JSON.parse(JSON.stringify(result)).value, isCacheable: true, api: dependency.serviceUUID });
    //         }
    //     }, (_error: ErrorEvent) => this.resolveDependencyURL(dependency.serviceUUID));
    // }
    // private getAndSetServiceRegistryInIndexDB(response: any, observer: PartialObserver<DependencyHttpDetail>, dependency: BaseDependencyProperty) {
    //     this.indexedDbService.get('ServiceRegistries').subscribe((data: any) => {
    //         if (!data) {
    //             data = [];
    //         }
    //         const serviceRegistry = data.find((registry: any) => registry.name === response.name);
    //         if (!serviceRegistry) {
    //             data.push(response);
    //             this.indexedDbService.set('ServiceRegistries', JSON.parse(JSON.stringify(data))).subscribe();
    //         }
    //     });
    //     observer.next({
    //         key: dependency.idPropertyName,
    //         url: response.value,
    //         httpRequestType: response.httpRequestType,
    //         isCacheable: true,
    //         api: dependency.serviceUUID
    //     });
    // }

    // private resolveDependencyURL(UUID: string): Observable<any> {//NOTE:unused
    //     return new Observable(observer => {
    //         this.getServiceUUIDAndModuleDeployment(UUID).subscribe((response: any[]) => {
    //             observer.next(response);
    //         }, (error) => observer.error(error));
    //     });
    // }

    // private getServiceUUIDAndModuleDeployment(uuid: string): Observable<any> {//NOTE:unused
    //     return new Observable(observer => {
    //         this.getApiByServiceUuid(uuid, observer);
    //     });
    // }

    // private getApiByServiceUuid(Uuid: string, observer: PartialObserver<any>) {//NOTE:unused
    //     this.indexedDbService.get('ServiceRegistries').subscribe((data: any) => {
    //         if (!data) {
    //             data = [];
    //         }
    //         const serviceRegistry = data.find((registry: any) => registry.name === Uuid);
    //         if (serviceRegistry) {
    //             observer.next(serviceRegistry);
    //         } else {
    //             this.getServiceUUIDFromDB(Uuid, observer);
    //         }
    //     });
    // }

    // private getServiceUUIDFromDB(Uuid: string, observer: PartialObserver<any>) {//NOTE:unused
    //     const payload = JSON.stringify({
    //         serviceUUID: Uuid
    //     });
    //     const url = this.baseUrl + 'serviceRegistries/serviceUUID';
    //     this.http.post(url, payload).subscribe(data => {
    //         const serviceRegistry = this.constructServiceRegistry(data);
    //         observer.next(serviceRegistry);
    //     }, (error: ErrorEvent) => observer.error(error));
    // }

    // private constructServiceRegistry(serviceApi: any) {//NOTE:unused

    //     return new ServiceRegistry(
    //         serviceApi.serviceRegistry.serviceUUID,
    //         this.configService.getConfig().serverURL + serviceApi.serviceRegistry.url,
    //         serviceApi.isCacheable,
    //         serviceApi.serviceRegistry.httpRequestType,
    //     );
    // }

}
