import { Observable, Subject } from 'rxjs';
import { filter, map, debounceTime, buffer } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { IdentityGenerator } from '../../utils/identity-generator';
import { HttpClientProvider } from '../abstract/http-client-provider';
import { WebSocketService } from './web-socket/web-socket.service';
import { SessionStorageService } from '../../../../src/app/components/storage/session-storage.service';
import { ConfigService } from '../config-service';


export class BaseGlobalWebSocketService implements IGlobalWebSocketService {

    private wsDataSubject: Subject<string>;
    private wsConnectionSubject: WebSocketSubject<string>;
    private baseUrl: string;
    private webSocketUrl: string;
    private restApiTopicUrl: string;
    private siteId: number;
    private subscribedTopics: Map<string, number> = new Map();
    private prevSubscibedTopic: Map<string, number> = new Map();
    private subscribeTopicsQueue = new Subject<string>();
    private unsubscribeTopicsQueue = new Subject<string>();
    private readonly bufferCount = 20;
    private reconnectSetInteval: number;
    private reconnectCount = 1;
    private reconnectIntervalTime = 5000;


    constructor(private http: HttpClientProvider, private wsService: WebSocketService,
        private sessionStorageService: SessionStorageService, private configService: ConfigService) {

    }

    init() {
        this.wsDataSubject = new Subject<string>();
        this.initUrls();
        this.initWebSocketConnection(this.webSocketUrl);
        this.bufferAndSubscribeData();
        this.bufferAndUnSubscribeData();
    }

    subscribeForTopic<T>(topic: string, _doesTopicContainSiteId = false, userIdentifier?: string): Observable<T> {
        let topicWithIdentifier = topic;
        if (userIdentifier) {
            topicWithIdentifier = topic + '_' + userIdentifier;
        }
        //REVISIT
        //  else {
        //     let userInfo = this.sessionStorageService.getUserProfile();
        //     topicWithIdentifier = topic + '_' + userInfo.userSubscription.identifier;
        // }

        if (!this.isTheTopicAlreadySubscribed(topicWithIdentifier)) {
            this.subscribedTopics.set(topicWithIdentifier, 1);
            this.subscribeTopicsQueue.next(topicWithIdentifier);
        } else {
            this.subscribedTopics.set(topicWithIdentifier, this.subscribedTopics.get(topic) + 1);
        }
        return this.wsDataSubject.pipe(
            filter((m) => {
                return m !== 'hb';
            }),
            map((m) => {
                return JSON.parse(m) as IWSMessage<any>;
            }),
            filter((m) => {
                return m.topicId === topicWithIdentifier;
            }),
            map((m) => {
                return m.message as T;
            }));
    }

    private bufferAndSubscribeData() {
        this.subscribeTopicsQueue.pipe(buffer(this.subscribeTopicsQueue.pipe(debounceTime(2000))), map((data: Array<any>) => {
            return this.getGroupedByResults(data);
        })).subscribe((groupedByTopics: string[][]) => {
            if (groupedByTopics?.length) {
                groupedByTopics?.forEach(topic => {
                    const url = this.constructUrl(topic.join('~'), 'subscribe');
                    const obs1 = this.http.get(url);
                    obs1.subscribe((d) => {
                        console.log('Subscribed for topic ' + d);
                    }, (error: any) => {
                        console.error('Error in subscribing ' + error);
                    });
                });
            }
        });
    }

    private getGroupedByResults(topics: string[]) {
        return new Array(Math.ceil(topics.length / this.bufferCount)).fill(0).map(_ => topics.splice(0, this.bufferCount));
    }

    unSubscribeForTopic(topic: string, _doesTopicContainSiteId = false, userIdentifier?: string): void {
        let topicWithIdentifier = topic;
        if (userIdentifier) {
            topicWithIdentifier = topic + '_' + userIdentifier;
        }
        //RIVISIT
        // else {
        //     let userInfo = this.sessionStorageService.getUserProfile();
        //     topicWithIdentifier = topic + '_' + userInfo.userSubscription.identifier;
        // }
        if (this.isTheTopicAlreadySubscribed(topicWithIdentifier)) {
            this.subscribedTopics.set(topicWithIdentifier, this.subscribedTopics.get(topic) - 1);
        }
        if (this.getSubscriptionCount(topicWithIdentifier) == 0) {
            this.unsubscribeTopicsQueue.next(topicWithIdentifier);
        }
    }

    private bufferAndUnSubscribeData() {
        this.unsubscribeTopicsQueue.pipe(buffer(this.unsubscribeTopicsQueue.pipe(debounceTime(2000))),
            map((data: Array<any>) => {
                return this.getGroupedByResults(data);
            })).subscribe((groupedByTopics: string[][]) => {
                if (groupedByTopics?.length) {
                    groupedByTopics?.forEach(topic => {
                        const url = this.constructUrl(topic.join('~'), 'unsubscribe');
                        this.http.get<boolean>(url)
                            .subscribe(() => {
                                // do nothing...
                                // console.log('Unsubscribed topic ' + d);
                            }, (error: any) => {
                                console.error('Error while unsubscribing ' + error);
                            });
                    });
                }
            });
    }


    private initUrls() {
        if (!this.baseUrl) {
            const baseUrl = this.configService.getConfig().wsBaseURL;
            this.baseUrl = baseUrl.replace('dedicated/', '');
            const requesterId = IdentityGenerator.guid();
            // let user = this.sessionStorageService.getUserInfo();
            // 'ws://192.168.1.123:90/devumConsole/ws/global?requesterId=6b7caa05-c431-4411-0f18-d1c103486160&subscribe=ORG_bhavani_customer_profile_manager'
            // this.webSocketUrl = `${this.baseUrl}global?requesterId=${requesterId}&userId=${user?.userAccount?.id}&subscribe=`;
            // this.restApiTopicUrl = `${this.baseUrl}modifyGlobal?requesterId=${requesterId}&userId=${user?.userAccount?.id}&`;
            this.webSocketUrl = `${this.baseUrl}global?requesterId=${requesterId}&subscribe=`;
            this.restApiTopicUrl = `${this.baseUrl}modifyGlobal?requesterId=${requesterId}`;
            this.restApiTopicUrl = this.restApiTopicUrl.replace('wss://', 'https://');
            this.restApiTopicUrl = this.restApiTopicUrl.replace('ws://', 'http://');
        }
    }
    
    private initWebSocketConnection(webSocketUrl: string) {
        if (!this.wsConnectionSubject) {
            this.wsConnectionSubject = this.wsService.connect(webSocketUrl);
            this.wsConnectionSubject.subscribe(
                (msg) => {
                    if(this.prevSubscibedTopic.size > 0){
                        Array.from(this.prevSubscibedTopic.keys()).forEach((topic: string)=>{
                            this.subscribeForTopic(topic);
                            this.subscribedTopics.set(topic, this.prevSubscibedTopic.get(topic));
                        });
                        this.prevSubscibedTopic.clear();
                    }
                    this.clearInterval();
                    this.wsDataSubject.next(msg);

                },
                (err) => {
                    console.log('Connection is closed.Trying to reconnect');
                    this.handleWebSocketReconnection(webSocketUrl);
                },
                () => {
                    console.log('complete');
                });

        }
    }

    private handleWebSocketReconnection(webSocketUrl: string){
        this.wsConnectionSubject.complete();
        this.wsConnectionSubject = null;
        if(this.prevSubscibedTopic.size === 0){
            this.prevSubscibedTopic = new Map(JSON.parse(JSON.stringify([...this.subscribedTopics])));
        }
        this.reconnectCount = 1;
        this.subscribedTopics.clear();
        this.reConnectWSWithIncrementInterval(webSocketUrl);
    }

    private reConnectWSWithIncrementInterval(webSocketUrl: string) {
        this.clearInterval();
        this.reconnectCount++;
        const interval = this.reconnectCount * this.reconnectIntervalTime;
        this.reconnectSetInteval = window.setInterval(() => {
            this.initWebSocketConnection(webSocketUrl);
        }, interval);
    }

    private clearInterval() {
        window.clearInterval(this.reconnectSetInteval);
    }

    private constructUrl(topic: string, topicsUsage: string) {
        const urlSuffix = `${this.restApiTopicUrl}&${topicsUsage}=${topic}`;
        return urlSuffix;
    }

    private isTheTopicAlreadySubscribed(topic: string): boolean {
        return this.subscribedTopics.get(topic) != null && this.getSubscriptionCount(topic) > 0;
    }

    private getSubscriptionCount(topic: string) {
        return this.subscribedTopics.get(topic) ? this.subscribedTopics.get(topic) : 0;
    }
}

export interface IWSMessage<T> {
    message: T;
    siteId: number;
    topicId: string;
}

export interface IGlobalWebSocketService {
    init(moduleId: number, siteId: number): void;
    subscribeForTopic<T>(topic: string, doesTopicContainSiteId: boolean): Observable<T>;
    unSubscribeForTopic(topic: string, doesTopicContainSiteId: boolean): void;
}