import { BaseEnumType } from "libs/dc-core/models/base.models";


export abstract class DenBaseEnumApi {
  public name = DenBaseEnumApi.name;
  protected abstract setDataset(): BaseEnumType[];

  private data: BaseEnumType[];

  constructor() {
    this.data = this.setDataset();
  }

  // getListObservable(): Observable<BaseEnumType[]> {
  //   return of(this.data);
  // }

  // getByIdObservable(id: number): Observable<BaseEnumType> {
  //   return of(this.data.find(d => d.name === id));
  // }

  getList(): BaseEnumType[] {
    return this.data;
  }

  getByName(name: string): BaseEnumType {
    return this.data.find(d => d.name === name);
  }
}
