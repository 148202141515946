import { Injector } from '@angular/core';
import { ConfigService } from 'libs/dc-core/providers/config-service';
import { DenBaseReaderApi } from './den-reader-base-api';
import { DenBaseWriterApi } from './den-writer-base-api';

export class DenReaderApi extends DenBaseReaderApi {
    constructor(injector: Injector, entityName: string) {
        const sharedService = injector.get<ConfigService>(ConfigService);
        super(injector, sharedService.getConfig().serverURL, entityName, false);
    }
}

export class DenCacheableReaderApi extends DenBaseReaderApi {
    constructor(injector: Injector, entityName: string) {
        const sharedService = injector.get<ConfigService>(ConfigService);
        super(injector, sharedService.getConfig().serverURL, entityName, true);
    }
}

export class DenWriterApi extends DenBaseWriterApi {
    constructor(injector: Injector, entityName: string) {
        const sharedService = injector.get<ConfigService>(ConfigService);
        super(injector, sharedService.getConfig().serverURL, entityName, false);
    }
}
export class DenHybridUserReaderApi extends DenBaseReaderApi {
    constructor(injector: Injector, entityName: string) {
        const sharedService = injector.get<ConfigService>(ConfigService);
        super(injector, sharedService.getConfig()['hybridUserURL'], entityName, false);
       // super(injector, sharedService.getConfig()['hybridUserURL'], entityName, sharedService.getConfig().serverURL, false);
    }
}