import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rt-reactivate-entity-dialog',
  templateUrl: './reactivate-entity-dialog.component.html',
  styleUrls: ['./reactivate-entity-dialog.component.scss']
})
export class ReactivateEntityDialogComponent implements OnInit {
  entityList: any;
  messageSplitByPipe: any;
  headerValues: string[] = [];
  keyValues: string[] = [];
  entityAsObject = [];
  removedEntityId: number
  constructor(
    public dialogRef: MatDialogRef<ReactivateEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  private getTableDataList(errorMessage) {
    let modifiedErrorMessage = errorMessage.summary;
    this.messageSplitByPipe = modifiedErrorMessage.split('\|');
    for (let i = 0; i < this.messageSplitByPipe.length; i++) {
      let splittedMessageArray = this.messageSplitByPipe[i].split(':');
      if (!splittedMessageArray[0] || !splittedMessageArray[1]) {
        splittedMessageArray[1] = splittedMessageArray[0];
        splittedMessageArray[0] = "Name";
      }
      this.entityAsObject[splittedMessageArray[0].trim()] = splittedMessageArray[1].trim();
    }
    this.getKeyValueData()
  }
  private getKeyValueData() {
    for (let key in this.entityAsObject) {
      this.headerValues.push(key);
      let value = this.entityAsObject[key];
      this.keyValues.push(value);
    }
  }
  ngOnInit(): void {
    if (this.data) {
      let errorMessage = JSON.parse(this.data.error.message);
      this.removedEntityId = errorMessage.id;
      this.getTableDataList(errorMessage)
    }
  }
}
