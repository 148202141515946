import { Injectable } from '@angular/core';
import { BaseTable, DependencyProperty, DynamicDependencyProperty } from '../../models/base.models';
import { CrudModel } from '../../models/shared.models';

@Injectable()
export class PageRefreshService {
  action: CrudModel<BaseTable> = new CrudModel<BaseTable>();
  dependencyList: DependencyProperty[] | DynamicDependencyProperty[] = new Array();
  constructor() { }
  setActionInstance(actionInstance: any, actionType: number) {
    this.action = { crudInstance: actionInstance, crudType: actionType };
  }
  getActionInstance(): CrudModel<BaseTable> {
    return this.action;
  }
}
